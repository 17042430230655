import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Base from "./Base";
import { getProduct } from "./helper/productHelper";
import ImagesHelper from "./helper/ImagesHelper";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ProductLabel, ProductCost, showAddToCartFunc } from "./TechnicCard";
import { getHome } from "./helper/homeHelper";
import { Link } from "react-router-dom";
import { PiHeadsetLight } from "react-icons/pi";
import { MdRotateLeft } from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";
import { LuTruck } from "react-icons/lu";
import { BsTelephone } from "react-icons/bs";
import { API_URL } from "../backend";
import Head from "./Head";
import CarouselInline from "./CarouselInline";
import { getProducts } from "./helper/productsHelper";
import { SwiperSlide } from "swiper/react";
import TechnicCard from "./TechnicCard";

export const ProductDetails = ({ inProduct, home, pid, details, reloadCartNo, setReloadCartNo }) => {
  const [product, setProduct] = useState([inProduct]);
  const [showAddToCart, setShowAddToCart] = useState(false);
  const [related_product, setRelatedProduct] = useState([]);
  const [similar_product, setSimilarProduct] = useState([]);

  useEffect(() => {
    const loadProduct = () => {
      getProduct(pid).then((data) => {
        if (data && data.error) {
          console.log(data.error);
        } else if (data) {
          setProduct(data);
        }
      });
    };
    if (!inProduct.length) {
      loadProduct();
    }

    window.scrollTo(0, 0);
  }, [pid, inProduct]);

  useEffect(() => {
    let prod = [];
    if (product.related_product_id) {
      for (let index = 0; index < product.related_product_id.length; index++) {
        prod.push(product.related_product_id[index].id);
      }
    }

    let similar_prod = [];
    if (product.similar_product_id) {
      for (let index = 0; index < product.similar_product_id.length; index++) {
        similar_prod.push(product.similar_product_id[index].id);
      }
    }

    if (details) {
      getProducts(`?related_product__id__in=${prod.length > 0 ? prod : [0]}`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setRelatedProduct(
            data &&
              data.results &&
              data.results.map((slide, index) => {
                return (
                  <SwiperSlide key={index} className="rounded h-100">
                    <TechnicCard
                      product={slide}
                      reloadCartNo={reloadCartNo}
                      setReloadCartNo={setReloadCartNo}
                      home={home}
                    />
                  </SwiperSlide>
                );
              })
          );
        }
      });

      getProducts(`?id__in=${similar_prod.length > 0 ? similar_prod : [0]}`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setSimilarProduct(
            data &&
              data.results &&
              data.results.map((slide, index) => {
                return (
                  <SwiperSlide key={index} className="rounded h-100">
                    <TechnicCard
                      product={slide}
                      reloadCartNo={reloadCartNo}
                      setReloadCartNo={setReloadCartNo}
                      home={home}
                    />
                  </SwiperSlide>
                );
              })
          );
        }
      });
    }

    setShowAddToCart(
      home.disable_cart ||
        product.call_for_order ||
        (home.not_display_price_non_existent && product.product_count === 0)
        ? false
        : true
    );

    return () => {};
  }, [product, home, reloadCartNo, setReloadCartNo, inProduct]);

  const r_s_prod = () => {
    if (details) {
      return (
        <>
          <Head title="کالاهای مشابه" />

          <div className="row m-auto">
            <CarouselInline items={similar_product} count={4} />
          </div>

          <Head title="کالاهای مرتبط" />

          <div className="row m-auto">
            <CarouselInline items={related_product} count={6} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-2 text-start IRANSans_Medium h2 mt-3" dir="ltr">
            {product.brand_logo &&
              product.brand_logo.map((brand, index) => {
                if (brand.logo) {
                  return (
                    <>
                      <Link key={index} to={"/brands/" + String(brand.english_name) + "/" + +String(brand.id)}>
                        <img
                          key={index}
                          src={API_URL + brand.logo}
                          style={{ maxHeight: "64px", maxWidth: "64px" }}
                          className="rounded text-start"
                          alt={brand.english_name}
                        />
                      </Link>
                    </>
                  );
                }
                return "";
              })}
          </div>
          <div className="col-10 text-end IRANSans_Medium h1 mt-3 my-auto py-auto" dir="ltr">
            {product.brand_names} {product.name_en}
            <br />
            <span className="text-end text-gray h4" dir="ltr">
              {product.short_en_desc}
            </span>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-12 col-md-6 order-md-1">
            <ProductLabel product_obj={product} home_obj={home} />

            <ImagesHelper className="col-12 col-sm-6 col-lg-3" product={product} />
          </div>
          <div className="col-12 col-md-6 text-start order-md-0">
            <b className="h1">{product.name}</b>
            <br />
            <br />
            <span className="text-gray h4">{product.short_fa_desc}</span>
            <br />
            <br />
            <hr />
            <br />
            <br />
            <div className="h1">
              <ProductCost product_obj={product} home_obj={home} />
            </div>
            <br />
            <div className="product-details-action m-auto">
              <br />
              {showAddToCartFunc(
                product,
                reloadCartNo,
                setReloadCartNo,
                showAddToCart,
                "  افزودن به سبد خرید",
                "btn-product btn-cart"
              )}
            </div>
            <br />
            <br />
            <hr />
            <div className="services text-center">
              <div className="my-auto ">
                <div className="row my-auto ">
                  <div className="col-6 col-lg-3">
                    <Link className="icon-box icon-box-side" aria-label="Link" to="/guaranty/">
                      <div className="icon-box-content mx-auto">
                        <span className="icon-box-icon">
                          <PiHeadsetLight />
                        </span>
                        <br />
                        <h3 className="icon-box-title">پشتیبانی فنی</h3>
                        <p style={{ fontSize: "13px" }}>سخت افزار و نرم افزار</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 col-lg-3">
                    <Link className="icon-box icon-box-side" aria-label="Link" to="/guaranty/">
                      <div className="icon-box-content mx-auto">
                        <span className="icon-box-icon">
                          <MdRotateLeft />
                        </span>
                        <br />
                        <h3 className="icon-box-title">۷ روز مهلت تست سلامت</h3>
                        <p>با توجه به شرایط</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 col-lg-3">
                    <Link className="icon-box icon-box-side" aria-label="Link" to="/send_order/">
                      <div className="icon-box-content mx-auto">
                        <span className="icon-box-icon">
                          <BsCurrencyDollar />
                        </span>
                        <br />
                        <h3 className="icon-box-title">پرداخت در محل</h3>
                        <p>در تهران</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 col-lg-3">
                    <Link className="icon-box icon-box-side" aria-label="Link" to="/send_order/">
                      <div className="icon-box-content mx-auto">
                        <span className="icon-box-icon">
                          <LuTruck />
                        </span>
                        <br />
                        <h3 className="icon-box-title">ارسال رایگان</h3>
                        <p style={{ fontSize: "10px" }} className="text-center">
                          سفارش‌های بالای یک میلیون و پانصدهزار تومان
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-8 text-gray">
                دسته بندی:{" "}
                {product.category_name_id &&
                  product.category_name_id.map((cat, index) => {
                    return (
                      <span key={index}>
                        <Link
                          to={String(
                            "/" +
                              String(cat.parent__text_en || cat.parent__text) +
                              "/" +
                              String(cat.text_en || cat.text) +
                              "/" +
                              String(cat.id)
                          )
                            .replace(/\s+/g, "-")
                            .toLowerCase()}
                        >
                          {cat.text}
                        </Link>
                        {", "}
                      </span>
                    );
                  })}
              </div>
              <div className="col float-end me-0 text-end">
                <Link className="" to="tel:+982166720097">
                  {" "}
                  مشاوره قبل از خرید <BsTelephone className="h2" />
                </Link>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>

        {r_s_prod()}

        <Tabs
          id="uncontrolled-tab-example"
          defaultActiveKey="desc"
          className={(!details && "d-none") || "mb-3 mx-auto text-start"}
        >
          <Tab eventKey="desc" title="توضیحات" className={(!details && "d-none") || "text-start"}>
            <div
              className={(!details && "d-none") || "text-start"}
              dangerouslySetInnerHTML={{ __html: product.description }}
            />
          </Tab>
          <Tab eventKey="tech_desc" className={(!details && "d-none") || "text-end"} title="مشخصات فنی">
            <div
              className={(!details && "d-none") || "text-end"}
              dangerouslySetInnerHTML={{ __html: product.technical_description }}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

const Product = () => {
  const [product, setProduct] = useState([]);
  const [title, setTitle] = useState("کالاها");

  const params = useParams();

  const pid = product ? product.id : 0;
  const [reloadCartNo, setReloadCartNo] = useState(false);
  const [home, setHome] = useState([]);

  useEffect(() => {
    const loadHome = () => {
      getHome().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setHome(data && data.count && data.results[0]);
        }
      });
    };
    loadHome();
  }, []);

  useEffect(() => {
    const loadProduct = () => {
      getProduct(params.id).then((data) => {
        if (data && data.error) {
          console.log(data.error);
        } else {
          setProduct(data);
        }
      });
    };
    loadProduct();
    setTitle(product.name);
    window.scrollTo(0, 0);
  }, [params.id, product.name]);

  return (
    <Base
      title={title}
      description="Welcome To Product"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
      className="container"
      extra={
        <Link to="/products/" className="my-auto">
          {" "}
          / محصولات
        </Link>
      }
    >
      <ProductDetails
        inProduct={product}
        home={home}
        pid={pid}
        details={true}
        reloadCartNo={reloadCartNo}
        setReloadCartNo={setReloadCartNo}
      />
    </Base>
  );
};

export default Product;
