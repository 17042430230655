import { API } from '../../backend'

export const getProducts = async (filter) => {
  try {
    const response = await fetch(`${API}Products/product_cards/${filter}`, { method: 'GET' })
    return await response.json()
  } catch (err) {
    return console.log(err)
  }
}
