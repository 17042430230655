import { API } from "../../backend";

export const getProjects = async (filter) => {
  try {
    const response = await fetch(`${API}Projects/${filter}`, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const getProject = async (id) => {
  try {
    if (id) {
      const response = await fetch(`${API}Projects/${id}/`, { method: "GET" });
      return await response.json();
    }
  } catch (err) {
    return console.log(err);
  }
};
