import { API_ROOT,API_URL } from "../../backend";
import { cartEmpty } from "../../core/helper/cartHelper";

export const signUp = async (user) => {
  try {
    const response = await fetch(`${API_ROOT}UserInfo/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const signIn = async (user) => {
  const formData = new FormData();

  for (const name in user) {
    console.log(user[name]);
    formData.append(name, user[name]);
  }


  for (var key of formData.keys()) {
    console.log("MY KEY: ", key);
  }

  try {
    const response = await fetch(`${API_URL}api-token-auth/`, {
      method: "POST",

      body: formData,
    });
    console.log("SUCCESS", response);
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const sendCode = async (user) => {
  const formData = new FormData();

  for (const name in user) {
    console.log(user[name]);
    formData.append(name, user[name]);
  }


  for (var key of formData.keys()) {
    console.log("MY KEY: ", key);
  }

  try {
    const response = await fetch(`${API_URL}send_code/`, {
      method: "POST",

      body: formData,
    });
    console.log("SUCCESS", response);
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    console.log("is Authenticated")
    return JSON.parse(localStorage.getItem("jwt"));
    //TODO: compare JWT with database json token
  } else {
    return false;
  }
};

export const signOut = () => {
  const userId = isAuthenticated() && isAuthenticated().user.id;

  console.log("USER ID: ", userId);

  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    cartEmpty(() => {});
    //next();

    return fetch(`${API_URL}login_view/`, {
      method: "GET",
    })
      .then((response) => {
        console.log("Sign out success");
        window.location.replace("/");
      })
      .catch((err) => console.log(err));
  }
};
