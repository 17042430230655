import React from "react";
import ReactSearchBox from "react-search-box";
// https://www.npmjs.com/package/react-search-box
import { getSearchProduct } from "./helper/productHelper";
import { useNavigate   } from "react-router-dom";
import { RiSearchLine } from "react-icons/ri";


const SearchBox = () => {

	  var res = []
	  getSearchProduct().then((data) => {
		if (data && data.error) {
			console.log(data.error);
		} else {
			for (let i of data) {
				res.push({key:i.id, value: i.name + " " + i.brand_english_name + " " + i.name_en,name_en: i.brand_english_name + " " + i.name_en})
			}
			// for (let i of data) {
			// 	res.push({key:i.id, value:})
			// }
			// setData(res);
		}
		});
		const navigate = useNavigate();
		
		const selectProduct = (record) =>{
			navigate(String("/"+String(record.item.name_en)+"/"+String(record.item.key)).replace(/\s+/g, "-").toLowerCase())
		}

    return (
        <ReactSearchBox
          placeholder="جستجوی کالا ..."
          value=""
		  leftIcon={<RiSearchLine className="h1 my-auto"/>}
		  iconBoxSize="50px"
		  fuseConfigs = {{
				isCaseSensitive: false,
				// includeScore: false,
				shouldSort: true,
				includeMatches: true,
				findAllMatches: true,
				// minMatchCharLength: 3,
				// location: 0,
				// threshold: 0.4,
				// distance: 100,
				// useExtendedSearch: false,
				ignoreLocation: true,
				// ignoreFieldNorm: false,
				// fieldNormWeight: 0,
		  }}
          data={res}
		//   onChange={(record) => setParam(record)}
		  onSelect = {(record) => selectProduct(record)}
          callback={(record) => console.log(record)}
        />
      );

}

export default SearchBox