import React, { useState, Fragment } from "react";
import ImageHelper from "./helper/ImageHelper";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { BsCart } from "react-icons/bs";
import { GrOverview } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { addItemToCart, removeItemFromCart } from "./helper/cartHelper";
import { isAuthenticated } from "../auth/helper";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ProductDetails } from "./Product";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="m-auto" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter m-auto">نمایش کالا</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductDetails inProduct={false} home={props.home} pid={props.pId} details={false} reloadCartNo={props.reloadCartNo} setReloadCartNo={props.setReloadCartNo}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>بستن</Button>
      </Modal.Footer>
    </Modal>
  );
}

export const ProductQuickView = ({ home, pId,reloadCartNo, setReloadCartNo }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setModalShow(true)}
        className="btn btn-block btn-outline-dark border-0 me-5 float-end bg-none"
      >
        <GrOverview className="h3 m-auto" />
      </Button>
      <MyVerticallyCenteredModal home={home} pId={pId} show={modalShow} reloadCartNo={reloadCartNo} setReloadCartNo={setReloadCartNo} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export const ProductLabel = ({ product_obj, home_obj }) => {
  return (
    <div className="col-12 p-2 text-end" style={{height:"25px"}}>
      {product_obj.new && product_obj.product_count !== 0 && (
        <span className="badge bg-new me-1">
          <span >جدید</span>
        </span>
      )}
      {product_obj.product_state && product_obj.product_state !== "new" && (
        <div className="badge me-1 bg-second-hand">
          <span >
            {(product_obj.product_state === "second_hand" && "دست‌دوم") ||
              (product_obj.product_state === "vitrin" && "ویترینی")}
          </span>
        </div>
      )}
      {product_obj.special_sale && product_obj.product_count !== 0  && (
        <span className="badge bg-special me-1">
          <span >فروش‌ویژه</span>
        </span>
      )}
      <span
        className={
          home_obj.not_display_price_non_existent && product_obj.product_count === 0
            ? "bg-secondary badge me-1"
            : "d-none"
        }
      >
        <span >ناموجود</span>
      </span>
      {product_obj.cost_in_off !== 0 && product_obj.product_count !== 0 && (
        <span className=" bg-off badge me-1">
          {" "}
          <span >تخفیف</span>
        </span>
      )}
    </div>
  );
};

export const ProductCost = ({ product_obj, home_obj }) => {
  return (
    <div
      className={
        product_obj.call_for_order || (home_obj.not_display_price_non_existent && product_obj.product_count === 0)
          ? "d-none"
          : "mt-3 text-center"
      }
    >
      <div
        className={
          (product_obj.cost_in_off !== 0 && "text-decoration-line-through persian text-gray") || "text-primary persian"
        }
      >
        <NumericFormat
          value={product_obj.cost}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          displayType="text"
          renderText={(value) => <b>{value}</b>}
        />{" "}
        تومان{" "}
      </div>

      {product_obj.cost_in_off !== 0 && (
        <div className="text-danger persian">
          <NumericFormat
            value={product_obj.cost_in_off}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            displayType="text"
            renderText={(value) => <b>{value}</b>}
          />{" "}
          تومان{" "}
        </div>
      )}
    </div>
  );
};

export const ProductTitles = ({ product_obj, home_obj }) => {
  return (
    <div className="mt-3">
      <div className={product_obj.call_for_order ? "mt-3 text-center text-dark bg-primary" : "d-none"}>
        برای سفارش تماس بگیرید
      </div>
      <div className="text-center px-auto IRANSans" dir="ltr"  style={{ color: "#666666", fontSize: "13px" }}>
        {product_obj.brand_names}
      </div>
      <div className="text-center px-auto IRANSans_Medium" dir="ltr" style={{ color: "#333333", fontSize: "13px" }}>
        {product_obj.name_en}
      </div>
      <div className="text-center px-auto" style={{ color: "#999999", fontSize: "13px" }}>
        {product_obj.category_names}
      </div>
      <ProductCost product_obj={product_obj} home_obj={home_obj} />
    </div>
  );
};

const addToCart = (product, reloadCartNo, setReloadCartNo) => {
  if (isAuthenticated()) {
    addItemToCart(product);
    setReloadCartNo(!reloadCartNo);
    setReloadCartNo(!reloadCartNo);
    setReloadCartNo(!reloadCartNo);
    console.log("Added to cart");
    alert('به سبد خرید اضافه شد')
  } else {
    alert("لطفا وارد حساب کاربری خود شوید!");
  }
};

export const showAddToCartFunc = (product, reloadCartNo, setReloadCartNo, showAddToCart, label='', classes="") => {
  return (
      <>
        <Button onClick={() => {addToCart(product, reloadCartNo, setReloadCartNo)}} className={classes === "" ? `btn btn-block btn-outline-dark border-0 ms-5 float-start bg-none${showAddToCart ? '': ' disabled'}`: `${classes}`  } disabled={!showAddToCart}>
          <BsCart className="h3 m-auto text-dark hover-white" />
          <span>{label}</span>
        </Button>
      </>
  );
};

const TechnicCard = ({
  product,
  reloadCartNo = false,
  setReloadCartNo = (f) => f,
  showAddToCart = true,
  removeFromCart = false,
  home,
}) => {
  const pId = product ? product.id : 0;
  showAddToCart =
    home.disable_cart || product.call_for_order || (home.not_display_price_non_existent && product.product_count === 0)
      ? false
      : showAddToCart;



  const showRemoveFromCart = () => {
    return (
      removeFromCart && (
        <button
          onClick={() => {
            //TODO: handle this too
            removeItemFromCart(removeFromCart, reloadCartNo, setReloadCartNo, reloadCartNo, setReloadCartNo);
            setReloadCartNo(!reloadCartNo);
            setReloadCartNo(!reloadCartNo);
            setReloadCartNo(!reloadCartNo);
            console.log("Product removed from cart");
          }}
          className="tech-remove-btn btn btn-block btn-danger m-auto"
        >
          <MdDeleteForever className="h2 m-auto" />
        </button>
      )
    );
  };



  return (
    <div className="photo h-100 m-auto">
      <div className={product.advertising ? "text-center bg-info h-100 m-auto" : "text-center h-100 m-auto"}>
        <div className="w-100 text-center m-auto">
          <ProductLabel product_obj={product} home_obj={home} />
          <Link
            to={String("/" + String(product.brand_names) + "-" + String(product.name_en) + "/" + String(pId) + "/")
              .replace(/\s+/g, "-")
              .toLowerCase()}
          >
            <ImageHelper product={product} />
          </Link>
          <div className="actions">
            {showAddToCartFunc(product, reloadCartNo, setReloadCartNo, showAddToCart)}
            |
            <ProductQuickView home={home} pId={pId} reloadCartNo={reloadCartNo} setReloadCartNo={setReloadCartNo} />
            {showRemoveFromCart()}
          </div>
          <br />
        </div>
        <ProductTitles product_obj={product} home_obj={home} />
      </div>
      <div className="glow-wrap">
        <i className="glow"></i>
      </div>
    </div>
  );
};

export default TechnicCard;
