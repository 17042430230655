import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";

import "./../../products_images_swiper_style.css";

export const getImages = async (url) => {
  try {
    const response = await fetch(url, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

const ImagesHelper = ({ className, product }) => {
  const [images, setImagesUrl] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    const loadImages = () => {
      if (product && product.product_images_link && product.product_images_link.length) {
        let requests = product.product_images_link.map((link, index) => {
          return getImages(link);
        });
        let myImages = [];
        Promise.all(requests).then((results) => {
          for (let index = 0; index < results.length; index++) {
            const element = results[index];
            myImages.push(element.image);
          }
          setImagesUrl(myImages);
        });
      }
    };
    loadImages();
  }, [product]);

  return (
    <div className="row">
      <Swiper
        style={{
          "--swiper-navigation-color": "blue",
          "--swiper-pagination-color": "blue",
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        className="mySwiperImage2 col"
      >
        {images &&
          images.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  key={index}
                  src={image}
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                  className="mb-3 rounded text-center"
                  alt={index}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiperImage col-2 float-end swiper-vertical"
      >
        {images &&
          images.map((image, index) => {
            return (
              <SwiperSlide className="swiper-image-slide" key={index}>
                <img
                  key={index}
                  src={image}
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                  className="mb-3 rounded text-center"
                  alt={index}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default ImagesHelper;
