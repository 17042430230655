import React, { useState, useEffect } from "react";
import Base from "./Base";
import ProjectCard from "./ProjectCard";
import { getProjects } from "./helper/projectsHelper";
import PaginatedItems from "./Pagination";


const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const [title] = useState("پروژه‌ها");
  const [reloadCartNo, setReloadCartNo] = useState(false);

  useEffect(() => {
    const loadProjects = () => {
      getProjects(`?offset=${offset}&limit=${limit}`).then((data) => {
        if (data && data.error) {
          console.log(data.error);
        } else {
          setProjects(data && data.results);
          setLength(data && data.results && data.results.length);
          setCount(data && data.count);
        }
      });
    };
    loadProjects();
  }, [offset, limit]);

  return (
    <Base
      title={title}
      description="Welcome To Projects"
      className="container"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
    >
      <div>
        نمایش {length} از {count} پروژه
      </div>

      <div className="row m-auto">
        {projects &&
          projects.map((project, index) => {
            return <>{ProjectCard(project, index)}</>;
          })}
      </div>

      <br />
      <div id="paginate">
        <PaginatedItems count={count} limit={limit} setLimit={setLimit} offset={offset} setOffset={setOffset} />
      </div>
    </Base>
  );
};

export default Projects;
