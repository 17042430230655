import React, { Fragment } from "react";
import { signOut, isAuthenticated } from "../auth/helper";
import { Link, useNavigate } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
// https://react-icons.github.io/react-icons/
import { Navbar, } from "react-bootstrap";
import Container from "react-bootstrap/Container";
// import { Navigate } from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};


const Menu = ({ history, path }) => {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-primary-dark header-top text-white m-auto">
      <Container className="text-white m-auto">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-white my-auto">

            {!isAuthenticated() && (
              <Fragment>
                <li className="nav-item my-auto">
                  <Link className="nav-link text-white my-auto" to="/sign-in">
                    ورود / ثبت نام
                  </Link>
                </li>
              </Fragment>
            )}

            {isAuthenticated() && (
              <li className="nav-item  my-auto">
                <span
                  role="button"
                  onClick={() => {
                    signOut();
                  }}
                  className="nav-link text-warning  my-auto"
                >
                  خروج
                </span>
              </li>
            )}
          </ul>
        <Navbar.Brand className="header-phone navbar-brand me-1 pe-0" href="tel:+982166720097">
          {" "}
          ۰۲۱۶۶۷۲۰۰۹۷ <BsTelephone className="h2" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default withRouter(Menu);
