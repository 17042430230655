import React, { useState, useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import { getBrandsFilter } from "./helper/brandHelper";
import 'react-checkbox-tree/src/less/react-checkbox-tree.less';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import { FaCheckSquare } from "react-icons/fa";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useParams } from "react-router-dom";

export default function BrandsFilter({checked, setChecked, expanded, setExpanded, categoryChecked, categoryExpanded, categoryNodes}) {
  const [nodes, setNodes] = useState([]);
  const params = useParams();

  useEffect(() => {

        let brands = categoryChecked.length  ? categoryChecked : []
        let brands_extends  =  categoryExpanded.length ? categoryExpanded : []
        let brand_list = brands.concat(brands_extends)
        getBrandsFilter(`?offset=0&limit=2000&brand_category_link__id__in=${brand_list.length>0 ? brand_list :params.category__id != null ? [Number(params.category__id)] : [null]}`).then((data) => {
          if (data && data.error) {
            // setError(data.error);
            console.log(data.error);
          } else {
            setNodes(data)
            if (!checked.every(val => Object.keys(data).map((elem, i) => {return String(data[i].value); }).includes(val))){
              setChecked(params.brand__id != null ? [Number(params.brand__id)] : [])
            }
          }
        });
  
    return () => {};
  }, [categoryChecked, categoryExpanded, checked, setChecked, params])


  return (
    <CheckboxTree
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={(checked) => setChecked(checked)}
      onExpand={(expanded) => setExpanded(expanded)}
      icons={{
        check: <FaCheckSquare />,
        uncheck: <MdCheckBoxOutlineBlank />,
        halfCheck:  '',
        expandClose:  '',
        expandOpen: '',
        expandAll:  '',
        collapseAll: '',
        parentClose:  '',
        parentOpen:  '',
        leaf:  ''
    }}
    />
  );
}
