import { API } from "../../backend";

const getFirstNavbar = async () => {
  try {
    const response = await fetch(`${API}Category/first_navbar/`, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const getCategoryFilter = async (filter) => {
  try {
    const response = await fetch(`${API}Category/category_filter/${filter}`, { method: 'GET' })
    return await response.json()
  } catch (err) {
    return console.log(err)
  }
}

export default getFirstNavbar;
