import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { API_URL } from "../backend";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { UserAddress, UserInfo, UserInfoUpdate } from "../user/helper/userInfoHelper";
import {updateOrderAddr} from "./helper/cartHelper"

export function TotalCostCart({ count, total_cost }) {
  return (
    <>
      جمع قیمت کالاها ({count} کالا) :{" "}
      <NumericFormat
        value={total_cost}
        thousandsGroupStyle="thousand"
        thousandSeparator=","
        displayType="text"
        renderText={(value) => <b>{value}</b>}
      />{" "}
      تومان
    </>
  );
}

const PaymentForm = ({ count, total_cost, orderId }) => {
  const [csrfToken, setCsrfToken] = useState("");
  const [addresses, setAddresses] = useState([]);

  const [values, setValues] = useState({
    url: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    mobile: "",
  });

  useEffect(() => {
    UserInfo()
      .then((data) => {
        setValues({
          url: data.results[0].url,
          first_name: data.results[0].first_name,
          last_name: data.results[0].last_name,
          email: data.results[0].email,
          phone_number: data.results[0].phone_number,
          mobile: data.results[0].mobile,
        });
      })
      .catch((e) => console.log(e));

    return () => {};
  }, []);

  useEffect(() => {

    UserAddress("GET")
      .then((data) => {
        if (data.results) {
          setAddresses(data.results);
        }
      })
      .catch((e) => console.log(e));


    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${API_URL}get-csrf-token/`, {
          method: "GET",
          credentials: "include", // Send cookies along with the request
        });
        const data = await response.json();
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (e.currentTarget[0].value === ''){
      alert('لطفا نام را وارد نمایید')
      return
    }
    if (e.currentTarget[1].value === ''){
      alert('لطفا نام خانوادگی را وارد نمایید')
      return
    }
    if (e.currentTarget[2].value === 'انتخاب آدرس'){
      alert('انتخاب آدرس اجباری است. لطفا در صورتی که آدرس های خود را وارد نکرده‌اید ،از قسمت حساب کاربری آدرس های خود را وارد نمایید.')
      return
    }
    if (total_cost > 200000000){
      alert('حداکثر مبلغ خرید اینترنتی 200 میلیون تومان است. لطفا برای خرید این محصولات با ما تماس بگیرید. 02166720097.')
      return
    }

    let { url, first_name, last_name, email, phone_number, mobile } = values;
    first_name = e.currentTarget[0].value
    last_name = e.currentTarget[1].value
    UserInfoUpdate({ url, first_name, last_name, email, phone_number, mobile }).catch((e) => console.log(e));
    updateOrderAddr(orderId, e.currentTarget[2].value)

    try {
      const response = await fetch(`${API_URL}get_token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${JSON.parse(localStorage.getItem("jwt")).token}`,
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({
          amount: total_cost,
        }),
      });

      const data = await response.json();
      if (data.token) {
        window.location.href = `https://sep.shaparak.ir/OnlinePG/SendToken?token=${data.token}`;
      } else {
        alert("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error submitting payment:", error);
      alert("Failed to connect to server");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>جزئیات سفارش</h3>
      <hr />
      <TotalCostCart count={count} total_cost={total_cost} />
      <hr />
      <Link to="/send_order/" className="text-primary">
        نحوه ارسال سفارش
      </Link>
      <br />
      هزینه‌ی ارسال در ادامه ثبت سفارش بر اساس آدرس و نحوه‌ی ارسال محاسبه و به این مبلغ اضافه خواهد شد
      <br />
      ارسال رایگان برای سفارش‌های بالای 1٬000٬000 تومان
      <hr />
      <Form.Control id="first_name" defaultValue={values.first_name} placeholder={'نام'} />
      <Form.Control id="last_name" defaultValue={values.last_name } placeholder={'نام خانوادگی'} />
      
      <Form.Select id="addr" aria-label="Default select" required className="my-2">
      <option>انتخاب آدرس</option>
      {addresses &&
          addresses.map((addr, index) => {
            return <option key={index} value={addr.url}>{addr.name}</option>;
          })}
      
      </Form.Select>
      <button type="submit" className="btn btn-primary btn-lg col-12 mb-3">
        {" "}
        پرداخت
      </button>
    </form>
  );
};

export default PaymentForm;
