import React from "react";
import { Link } from "react-router-dom";

function ProjectCard(project, index) {
  return (
    <div className="col-6 col-md-3 col-lg-3 mx-auto text-center" key={index}>
      <img src={project.post_image} alt={project.title} className="w-100 rounded" />
      <div className="text-center w-100">{project.title}</div>
      <Link
        className="read-more"
        aria-label="Link"
        to={String("/project/" + project.url_addr + "/" + project.title + "/" + project.id + "/")
          .replace(/\s+/g, "-")
          .toLowerCase()}
      >
        مطالعه بیشتر
      </Link>
    </div>
  );
}

export default ProjectCard;
