import React from "react";
// Import Swiper React components
import { FreeMode, Navigation, Pagination} from "swiper/modules";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";


const CarouselInline = ({items, count=6, navigation_value=true}) => {
  return (
      <Swiper
        style={{
          "--swiper-navigation-color": "blue",
          "--swiper-navigation-size": "25px",
        }}
        dir="rtl"
        modules={[Navigation, Pagination,FreeMode]}
        slidesPerView={1}
        spaceBetween={6}
        freeMode={true}
        grabCursor={true}
        navigation={navigation_value}
        // centeredSlides={true}
        // pagination={{
        //   clickable: true,
        // }}
        loop={true}
        className="mySwiper row mx-auto p-4"
        // scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: count,
            spaceBetween: 10,
          },
        }}
      >
        {items}

      </Swiper>
  );
}

export default CarouselInline
