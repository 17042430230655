import React from "react";
import Base from "./Base";
import GoogleMapReact from "google-map-react";
import { default_image } from "../backend";
import { Link } from "react-router-dom";
import { CiLocationOn, CiClock2 } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

const Contact = () => {
  const defaultProps = {
    center: {
      lat: 35.69519,
      lng: 51.414418,
    },
    zoom: 16,
  };
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  return (
    <Base title="تماس با ما" description="">
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <span lat={35.69519} lng={51.414418} className="h1 text-primary">
            <img src={default_image} className="rounded" height={50} width={50} alt={"فروشگاه تکنیک"} />
          </span>
        </GoogleMapReact>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12 h1 my-5 mx-auto">اطلاعات تماس</div>
            <div className="col-6">
              <div className="h2">آدرس دفتر</div>
              <div className="text-start">
                <p>
                  <CiLocationOn className="me-2 h2" />
                  تهران، جمهوری، تقاطع ۳۰ تیر، ابتدای میرزا کوچک‌ خان، پلاک یک
                </p>
                <p>
                  <Link to="tel:+982166720097" className="text-dark">
                    <BsTelephone className="me-2 h2" /> ۰۲۱۶۶۷۲۰۰۹۷
                  </Link>
                </p>
                <p>
                  <Link to="mailto:info@technicav.com" className="text-dark">
                    <AiOutlineMail className="me-2 h2" />
                    info@technicav.com
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="h2">ساعت کاری</div>
              <p>
                <CiClock2 className="me-2 h2" />
                شنبه تا چهارشنبه ۱۰ الی ۱۹
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12 h1 my-5 mx-auto">فرم تماس</div>
            <div className="col-6"></div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Contact;
