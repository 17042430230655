import { Navbar, Nav, NavDropdown, } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import getFirstNavbar from "./helper/categoryHelper";
import { IoIosArrowDown } from "react-icons/io";

export const product_link = "/products";

const HomeNavbar = ({MyClassName}) => {
  const [len, setLen] = useState(0);
  const [firstNavbar, setFirstNavbar] = useState([]);
  const [error, setError] = useState(false);





  useEffect(() => {
    const loadFirstNavbar = () => {
      getFirstNavbar().then((data) => {
        if (data && data.error) {
          setError(data && data.error);
          console.log(error);
        } else {
          setFirstNavbar(data);
          setLen(data.length)
          let newShow =  []
          for (let index = 0; index < len; index++) {
            newShow.push(false)
            
          }
        }
      });
    };
    loadFirstNavbar();
  }, [len,error]);

  return (
    <Navbar collapseOnSelect expand="lg" className={"bg-white mx-auto mb-3 "+MyClassName} sticky="top">
      <Container className="m-auto">
        {/* <Link className="navbar-brand m-auto" to="/">فروشگاه تکنیک</Link> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto text-start">
            <li className="m-lg-auto nav-item text-start">
              <Link className="m-auto nav-link text-dark text-start" to={product_link}>
                محصولات
              </Link>
            </li>
            {firstNavbar &&
              firstNavbar.map((navbar, index1) => {
                if (navbar.children.length) {
                  return (
                    <div key={index1}>
                      <NavDropdown
                        title={navbar && <span className="hover-blue text-dark"><IoIosArrowDown className="text-gray"/><span>{navbar.text}</span></span>}
                        id={index1}
                        renderMenuOnMount={true}
                        className="mx-3"
                      >
                        {navbar.children.map((child, index2) => {
                          return (
    
                              <li key={index2} className="mx-auto my-1" style={{'minWidth':'200px'}}>
                                <Link className="dropdown-item mx-auto my-2 font-40 font-gray" to={String("/" + String(child.parent__text_en || child.parent__text) + "/" + String(child.text_en || child.text)+ "/" + String(child.id)).replace(/\s+/g, "-").toLowerCase()}>
                                  {child.text}
                                </Link>
                              </li>
                          );
                        })}
                      </NavDropdown>
                    </div>
                  );
                }
                return (
                  <li className="my-auto nav-item mx-3" key={index1}>
                    <Link className="m-auto nav-link text-dark" to={String( "/" + String(navbar.parent__text_en || navbar.parent__text)+ "/" + String(navbar.text_en || navbar.text) + "/" + String(navbar.id)).replace(/\s+/g, "-").toLowerCase()}>
                      {navbar.text}
                    </Link>
                  </li>
                );
              })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HomeNavbar;
