import React from "react";
import { Link } from "react-router-dom";

function BlogCard(blog, index) {
  return (
    <div className="col-6 col-md-3 col-lg-3 mx-auto text-center" key={index}>
      <img src={blog.post_image} alt={blog.title} className="w-100 round-radius" />
      <div className="text-center w-100">{blog.title}</div>
      <Link
        className="read-more"
        aria-label="Link"
        to={String("/blog/" + blog.url_addr + "/" + blog.title + "/" + blog.id + "/")
          .replace(/\s+/g, "-")
          .toLowerCase()}
      >
        مطالعه بیشتر
      </Link>
    </div>
  );
}

export default BlogCard;
