import React from "react";
import { Link } from "react-router-dom";
import { PiHeadsetLight } from "react-icons/pi";
import { MdRotateLeft } from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";
import { LuTruck } from "react-icons/lu";
import { SocialIcon } from 'react-social-icons'

export const services = () => {
  return(
  <div className="services icon-boxes-container">
  <div className="container my-auto ">
    <div className="row my-auto ">
      <div className="col-6 col-lg-3">
        <Link className="icon-box icon-box-side" aria-label="Link" to="/guaranty/">
          <span className="icon-box-icon">
            <PiHeadsetLight />
          </span>
          <div className="icon-box-content">
            <h3 className="icon-box-title">پشتیبانی فنی</h3>
            <p>سخت افزار و نرم افزار</p>
          </div>
        </Link>
      </div>
      <div className="col-6 col-lg-3">
        <Link className="icon-box icon-box-side" aria-label="Link" to="/guaranty/">
          <span className="icon-box-icon">
            <MdRotateLeft />
          </span>
          <div className="icon-box-content">
            <h3 className="icon-box-title">۷ روز مهلت تست سلامت</h3>
            <p>با توجه به شرایط</p>
          </div>
        </Link>
      </div>
      <div className="col-6 col-lg-3">
        <Link className="icon-box icon-box-side" aria-label="Link" to="/send_order/">
          <span className="icon-box-icon">
            <BsCurrencyDollar />
          </span>
          <div className="icon-box-content">
            <h3 className="icon-box-title">پرداخت در محل</h3>
            <p>در تهران</p>
          </div>
        </Link>
      </div>
      <div className="col-6 col-lg-3">
        <Link className="icon-box icon-box-side" aria-label="Link" to="/send_order/">
          <span className="icon-box-icon mb-5">
            <LuTruck />
          </span>
          <div className="icon-box-content">
            <h3 className="icon-box-title">ارسال رایگان</h3>
            <p>سفارش‌های بالای یک میلیون و پانصدهزار تومان</p>
          </div>
        </Link>
      </div>
    </div>
  </div>
</div>
  )
}

const Footer = () => {
  return (
    <footer className="footer bg-gray-dark text-white mt-5">
      {services()}
      <div className="footer-middle pt-5">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className="widget widget-about">
                <img src="/images/logo.svg" className="footer-logo" alt="Footer Logo" width="105" height="25" />
                <p>
                  هدف تکنیک ارائه‌ی بهترین مشاوره و خدمات برای خرید تجهیزات استودیویی صدا، تصویر و آکوستیک است تا شما
                  بتوانید به کیفیت استاندارد جهانی دست یابید.
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">ارتباط با فروشگاه</h4>
                <div className="widget widget-about">
                  <p>
                    تهران، جمهوری، تقاطع ۳۰ تیر، ابتدای میرزا کوچک‌ خان، پلاک یک
                    <br />
                    کد پستی: ۱۱۳۱۶۶۳۱۱۴
                    <br />
                    تلفن:{" "}
                    <Link to="tel:+982166720097" className="text-light hover-blue-footer">
                      ۰۲۱۶۶۷۲۰۰۹۷
                    </Link>
                    <br />
                    <Link to="mailto:info%40technicav.com" className="text-light hover-blue-footer">
                      info[at]technicav.com
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="widget links">
                <h4 className="widget-title">لینکهای کاربردی</h4>
                <div className="row">
                  <div className="col-6">
                    <ul className="widget-list">
                      <li>
                        <Link to="/about_us" className="text-light hover-blue-footer">
                          درباره ما
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact/" className="text-light hover-blue-footer">
                          تماس با ما
                        </Link>
                      </li>
                      <li>
                        <Link to="/questions" className="text-light hover-blue-footer">
                          سوالات متداول
                        </Link>
                      </li>
                      <li className="p-1 nav-item">
                        <Link className="text-light hover-blue-footer" to="/leasing">
                          فروش اقساطی
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="widget-list">
                      <li>
                        <Link to="/guaranty/" className="text-light hover-blue-footer">
                          شرایط گارانتی
                        </Link>
                      </li>
                      <li>
                        <Link to="/save_order/" className="text-light hover-blue-footer">
                          نحوه ثبت سفارش
                        </Link>
                      </li>
                      <li>
                        <Link to="/send_order/" className="text-light hover-blue-footer">
                          نحوه ارسال سفارش
                        </Link>
                      </li>
                      <li className="p-1 nav-item">
                        <Link className="text-light hover-blue-footer" to="/bank_account">
                          شماره حساب
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <div className="widget widget-namad">
                  <Link
                    id="enamad"
                    rel="noopener"
                    to="https://trustseal.enamad.ir/?id=190297&amp;Code=mVKyIP5sMmZYKhwsoFbV"
                    referrerPolicy="origin"
                  >
                    <img
                      src="/images/enamad.png"
                      alt="logo-enamad"
                      id="mVKyIP5sMmZYKhwsoFbV"
                      className="lazyloaded"
                      data-ll-status="loaded"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      id="nbqeesgtoeuksizpsizpnbqe"
                      className="samandehi lazyloaded"
                      alt="logo-samandehi"
                      src="/images/samandehi.webp"
                      data-ll-status="loaded"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom m-auto">
        <div className="container my-auto">
          <div className="row mx-auto">
            <div className="col-12 col-lg-9 order-lg-0 order-1">
            <p className="footer-copyright">Copyright © 2023 تمامی حقوق نزد فروشگاه تکنیک محفوظ می باشد.</p>
            </div>
            <div className="col-12 col-lg-3 order-lg-1 order-0 text-center"> 
              <SocialIcon  url="https://youtube.com/@audio-mag?si=SCFC7mqPIq-_svuN/" />
              <SocialIcon  url="https://www.facebook.com/technicav/" />
              <SocialIcon  url="https://www.instagram.com/technicav_com/" />
              <SocialIcon  url="https://t.me/technicav_com/" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
