import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Base from "./Base";
import getOrg from "./helper/orgHelper";
import getQuestion from "./helper/questionHelper";
import Head from "./Head";
import { Link } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";

const BLACK = "black";
const BLUE = "rgba(0, 0, 255, 0.6)";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Card.Header style={{ backgroundColor: isCurrentEventKey ? "white" : "whitesmoke" }}>
      <button
        type="button"
        className="border-0 w-100 text-start"
        style={{ color: isCurrentEventKey ? BLACK : BLUE, backgroundColor: isCurrentEventKey ? "white" : "whitesmoke" }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    </Card.Header>
  );
}

const Questions = () => {
  const [orgs, setOrgs] = useState([]);
  const [questionArray, setQuestionArray] = useState({});

  useEffect(() => {
    getOrg().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setOrgs(data && data.results);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    getQuestion().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        var obj = {};
        for (let index = 0; index < orgs.length; index++) {
          obj[index] = data.results.filter((org) => org.org === orgs[index].url);
        }
        setQuestionArray(obj);
      }
    });
    window.scrollTo(0, 0);
    return () => {};
  }, [orgs]);

  return (
    <Base title="سوالات متداول" description="">
      {/* defaultActiveKey="0" */}
      {orgs &&
        orgs.map((org, index) => {
          return (
            <span key={index}>
              <Head title={org.name} />
              <Accordion className="text-start">
                {questionArray[index] &&
                  questionArray[index].map((question, index2) => {
                    return (
                      <Card key={index2}>
                        <ContextAwareToggle eventKey={index2}>{question.question}</ContextAwareToggle>
                        <Accordion.Collapse eventKey={index2}>
                          <Card.Body>{question.answer}</Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
              </Accordion>
            </span>
          );
        })}
      <Head title="تماس با کارشناسان گروه تکنیک" />
      <div className="text-center h2">
        <p>
          در صورتی که پاسخ سوالتان را پیدا نکردید میتوانید از طریق راههای ارتباطی زیر با کارشناسان گروه تکنیک تماس حاصل
          فرمایید.
        </p>
        <div className="faq-tel h1 my-5">
          <Link to="tel:+982166720097">
            ۰۲۱۶۶۷۲۰۰۹۷ <BsTelephone className="h1" />
          </Link>
        </div>
        <Link className="btn btn-outline-primary-2" aria-label="Link" to="/contact/">
          <Link className="read-more h3 m-2" aria-label="Link" to={'/contact/'}>تماس با ما</Link>
        </Link>
      </div>
    </Base>
  );
};

export default Questions;
