import React, { useState, useEffect } from "react";
import Base from "../core/Base";
import { signOut } from "../auth/helper";
import { UserInfo, UserInfoUpdate, UserAddress, States, City, DataByUrl } from "./helper/userInfoHelper";
import { API_ROOT } from "../backend";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Order from "../core/Orders";

export const ProfileForm = () => {
  const [values, setValues] = useState({
    url: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    mobile: "",
  });

  const { url, first_name, last_name, email, phone_number, mobile } = values;

  useEffect(() => {
    UserInfo()
      .then((data) => {
        setValues({
          url: data.results[0].url,
          first_name: data.results[0].first_name,
          last_name: data.results[0].last_name,
          email: data.results[0].email,
          phone_number: data.results[0].phone_number,
          mobile: data.results[0].mobile,
        });
      })
      .catch((e) => console.log(e));

    return () => {};
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    UserInfoUpdate({ url, first_name, last_name, email, phone_number, mobile }).catch((e) => console.log(e));
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="first_name">
        <Form.Label>نام</Form.Label>
        <Form.Control
          size="lg"
          value={first_name}
          onChange={handleChange("first_name")}
          type="text"
          placeholder="نام"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="last_name">
        <Form.Label>نام خانوادگی</Form.Label>
        <Form.Control
          size="lg"
          value={last_name}
          onChange={handleChange("last_name")}
          type="text"
          placeholder="نام خانوادگی"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="email">
        <Form.Label>ایمیل</Form.Label>
        <Form.Control size="lg" value={email} onChange={handleChange("email")} type="email" placeholder="Email" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>

      <Form.Group className="mb-3" controlId="phone_number">
        <Form.Label>تلفن ثابت</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          value={phone_number}
          onChange={handleChange("phone_number")}
          placeholder="تلفن ثابت"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="mobile">
        <Form.Label>موبایل</Form.Label>
        <Form.Control size="lg" type="text" value={mobile} onChange={handleChange("mobile")} placeholder="موبایل" />
      </Form.Group>

      <Button variant="primary" className="form-control" onClick={onSubmit}>
        <span className="h4">ذخیره</span>
      </Button>
    </Form>
  );
};

export const AddressList = (props) => {
  const [address, setAddress] = useState([]);
  const [method, setMethod] = useState("GET");
  const [states, setStates] = useState([]);
  const [cities, setCity] = useState([]);
  const [reloadAddress, setReloadAddress] = useState(true);

  const [values, setValues] = useState({
    url: "",
    name: "",
    state: "",
    city: "",
    detail: "",
    post_code: "",
    delivery_info: "",
  });
  const [error, setError] = useState({
    name_error: "",
    state_error: "",
    city_error: "",
    detail_error: "",
    post_code_error: "",
    delivery_info_error: "",
  });

  const { url, name, state, city, detail, post_code, delivery_info } = values;

  useEffect(() => {
    City(values.state)
      .then((data) => {
        setCity(data);
      })
      .catch((e) => console.log(e));

    return () => {};
  }, [values]);

  useEffect(() => {
    UserAddress("GET")
      .then((data) => {
        setAddress(data.results);
      })
      .catch((e) => console.log(e));

    return () => {};
  }, [reloadAddress]);

  const onSubmit = (event) => {
    event.preventDefault();
    UserAddress(method, { url, name, state, city, detail, post_code, delivery_info })
      .then((data) => {
        if (data) {
          setError({
            name_error: data.name,
            state_error: data.state,
            city_error: data.city,
            detail_error: data.detail,
            post_code_error: data.post_code,
            delivery_info_error: data.delivery_info,
          });
        } else {
          props.setModalShow(false);
          setReloadAddress(!reloadAddress);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const openCreateModal = () => {
    States()
      .then((data) => {
        setStates(data);
        setValues({
          url: "",
          name: "",
          state: "",
          city: "",
          detail: "",
          post_code: "",
          delivery_info: "",
        });
        setError({
          name_error: "",
          state_error: "",
          city_error: "",
          detail_error: "",
          post_code_error: "",
          delivery_info_error: "",
        });
        setMethod("POST");
        props.setModalShow(true);
      })
      .catch((e) => console.log(e));
  };

  const openEditModal = (addUrl) => {
    setError({
      name_error: "",
      state_error: "",
      city_error: "",
      detail_error: "",
      post_code_error: "",
      delivery_info_error: "",
    });

    States()
      .then((data) => {
        setStates(data);
      })
      .catch((e) => console.log(e));

    DataByUrl(addUrl)
      .then((data) => {
        setValues({
          url: data.url,
          name: data.name,
          state: data.state,
          city: data.city,
          detail: data.detail,
          post_code: data.post_code,
          delivery_info: data.delivery_info,
        });

        setMethod("PUT");
        props.setModalShow(true);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      آدرس های زیر برای ارسال کالا به مقصد استفاده می شود.
      <br />
      {address &&
        address.map((a, index) => {
          return (
            <div key={index} className="bg-info w-75 mx-auto rounded my-3">
              {a.name}
              {/* <br /> */}
              {/* {a.state}
              <br />
              {a.city} */}
              <br />
              {a.detail}
              <br />
              {a.post_code}
              <br />
              {a.delivery_info}
              <br />
              <Button onClick={() => openEditModal(a.url)} className="btn btn-lg btn-outline-success bg-white">
                ویرایش
              </Button>
            </div>
          );
        })}
      <Button
        onClick={() => openCreateModal()}
        className="btn btn-block btn-lg btn-outline-info ms-5 float-start bg-white"
      >
        + افزودن آدرس
      </Button>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="m-auto" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter m-auto">آدرس</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>نام آدرس</Form.Label>
              <Form.Control
                size="lg"
                value={name}
                onChange={handleChange("name")}
                type="text"
                placeholder="نام آدرس"
                required
              />
              <Form.Text className="text-muted">
                * نام آدرس  جهت سهولت در انتخاب آدرس ها در زمان پرداخت است.
                <div className="text-danger">{error.name_error}</div>
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="state">
              <Row>
                <Col>
                  <Form.Label>استان*</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    size="lg"
                    value={state}
                    onChange={handleChange("state")}
                    placeholder="استان"
                  >
                    <option>استان</option>
                    {states &&
                      states.map((s, index) => {
                        return (
                          <option value={`${API_ROOT}States/${s.id}/`} key={index}>
                            {s.text}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    <div className="text-danger">{error.state_error}</div>
                  </Form.Text>
                </Col>
                <Col>
                  <Form.Label>شهر*</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    size="lg"
                    value={city}
                    onChange={handleChange("city")}
                    placeholder="َشهر"
                  >
                    <option>شهر</option>
                    {cities &&
                      cities.map((s, index) => {
                        return (
                          <option value={`${API_ROOT}States/${s.id}/`} key={index}>
                            {s.text}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    <div className="text-danger">{error.city_error}</div>
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="detail">
              <Form.Label>جزئیات آدرس پستی *</Form.Label>
              <Form.Control
                size="lg"
                as="textarea"
                rows={3}
                value={detail}
                onChange={handleChange("detail")}
                placeholder="جزئیات آدرس پستی"
              />
              <Form.Text className="text-muted">
                <div className="text-danger">{error.detail_error}</div>
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="post_code">
              <Row>
                <Col>
                  <Form.Label>کد پستی *</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={post_code}
                    onChange={handleChange("post_code")}
                    placeholder="کد پستی"
                    required
                  />
                  <Form.Text className="text-muted">
                    <div className="text-danger">{error.post_code_error}</div>
                  </Form.Text>
                </Col>
                <Col>
                  <Form.Label>نام یا تلفن تحویل گیرنده (اختیاری)</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={delivery_info}
                    onChange={handleChange("delivery_info")}
                    placeholder="تحویل گیرنده"
                  />
                  <Form.Text className="text-muted">
                    <div className="text-danger">{error.delivery_info_error}</div>
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>

            <Button variant="primary" className="form-control btn btn-lg" onClick={onSubmit}>
              <span className="h4">ذخیره</span>
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="danger" onClick={props.onHide} className="btn btn-lg">
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const LeftTabs = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Tab.Container id="left-tabs" defaultActiveKey="orders">
      <Row>
        <Col sm={3}>
          <Nav variant="tabs" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="orders">سفارش ها</Nav.Link>
            </Nav.Item>
            <hr
              style={{
                color: "black",
                backgroundColor: "black",
                height: 1,
              }}
            />
            <Nav.Item>
              <Nav.Link eventKey="profile">مشخصات کاربر</Nav.Link>
            </Nav.Item>
            <hr
              style={{
                color: "black",
                backgroundColor: "black",
                height: 1,
              }}
            />

            <Nav.Item>
              <Nav.Link eventKey="address">آدرس ها</Nav.Link>
            </Nav.Item>
            <hr
              style={{
                color: "black",
                backgroundColor: "black",
                height: 1,
              }}
            />
            <Nav.Item>
              <span
                role="button"
                onClick={() => {
                  signOut();
                }}
                className="nav-link text-dark h1 bg-danger"
              >
                خروج
              </span>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <ProfileForm />
            </Tab.Pane>
            <Tab.Pane eventKey="orders">
              <Order />
            </Tab.Pane>
            <Tab.Pane eventKey="address">
              <AddressList show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

const UserDashboard = () => {
  return (
    <Base title="حساب کاربری" description="this is user dashboard">
      <LeftTabs />
    </Base>
  );
};

export default UserDashboard;
