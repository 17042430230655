
import { useParams } from 'react-router-dom';

const UseParamToSearchParam = () => {
  const params = useParams();

  // تبدیل آبجکت پارامترها به رشته query
  const queryString = new URLSearchParams(params).toString();

  return queryString;
};

export default UseParamToSearchParam;
