import React, { useState, useEffect } from "react";

import { getProducts } from "./helper/productsHelper";
import { getBlogs } from "./helper/blogsHelper";
import { getHome } from "./helper/homeHelper";
import getBrands from "./helper/brandHelper";
import getOrgans from "./helper/organHelper";
import getFirstNavbar from "./helper/categoryHelper";

import Head from "./Head";
import ResponsiveCarousel from "./ResponsiveCarousel";
import TechnicCard from "./TechnicCard";
import BlogCard from "./BlogCard";
import CarouselInline from "./CarouselInline";
import { SwiperSlide } from "swiper/react";
import Base from "./Base";

import { Link } from "react-router-dom";

import { default_image, API_URL } from "../backend";
import "../styles.css";

export default function Home() {
  const [products, setProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [softwareProducts, setSoftwareProducts] = useState([]);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  // const [error, setError] = useState(false);
  const [firstNavbar, setFirstNavbar] = useState([]);
  const [banners, setBanner] = useState([]);
  const [brands, setBrands] = useState([]);
  const [organs, setOrgans] = useState([]);
  const [reloadCartNo, setReloadCartNo] = useState(false);

  useEffect(() => {
    const loadFirstNavbar = () => {
      getFirstNavbar().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setFirstNavbar((data && data[0]) || data);
        }
      });
    };
    loadFirstNavbar();

    const loadBanners = () => {
      getHome().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setBanner(data && data.count && data.results[0]);
        }
      });
    };
    loadBanners();

    const loadBrands = () => {
      getBrands(`?offset=0&limit=20`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setBrands(
            data &&
            data.results &&
            data.results.map((slide, index) => {
              return (
                <SwiperSlide key={index} className="round-radius h-100 my-4">
                  <img
                    src={slide.logo || default_image}
                    height={100}
                    width={100}
                    alt={slide.name}
                  />
                </SwiperSlide>
              );
            })
          );
        }
      });
    };
    loadBrands();

    const loadOrgans = () => {
      getOrgans(`?offset=0&limit=20`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setOrgans(
            data &&
            data.results &&
            data.results.map((slide, index) => {
              return (
                <SwiperSlide key={index} className="round-radius h-100 my-4">
                  <img
                    src={slide.logo || default_image}
                    height={100}
                    width={100}
                    alt={slide.name}
                  />
                </SwiperSlide>
              );
            })
          );
        }
      });
    };
    loadOrgans();

    const loadSpecialProducts = () => {
      getProducts(`?product_count__gt=0&special_sale=true&offset=0&limit=6`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setSpecialProducts(data && data.results);
        }
      });
    };
    loadSpecialProducts();

    const loadSoftwareProducts = () => {
      getProducts(`?product_count__gt=0&category__is_software=true&offset=0&limit=3&ordering=-order`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setSoftwareProducts(data && data.results);
        }
      });
    };
    loadSoftwareProducts();

    const loadSuggestedProducts = () => {
      getProducts(`?product_count__gt=0&product_count__gt=0&suggest=true&offset=0&limit=3`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setSuggestedProducts(data && data.results);
        }
      });
    };
    loadSuggestedProducts();

    const loadBlogs = () => {
      getBlogs(`?offset=0&limit=4`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setBlogs(data && data.results);
        }
      });
    };
    loadBlogs();
  }, [reloadCartNo]);

  useEffect(() => {
    const loadPopularProducts = () => {
      getProducts(`?product_count__gt=0&popular=true&offset=0&limit=20`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setPopularProducts(
            data &&
            data.results &&
            data.results.map((slide, index) => {
              return (
                <SwiperSlide key={index} className="rounded h-100">
                  <TechnicCard
                    product={slide}
                    reloadCartNo={reloadCartNo}
                    setReloadCartNo={setReloadCartNo}
                    home={banners}
                  />
                </SwiperSlide>
              );
            })
          );
        }
      });
    };
    loadPopularProducts();

    return () => { };
  }, [banners, reloadCartNo]);

  useEffect(() => {
    const loadFirstProducts = () => {
      if (firstNavbar && firstNavbar.id) {
        getProducts(`?product_count__gt=0&new=true&category__parent__id=${firstNavbar.id}&offset=0&limit=20`).then((data) => {
          if (data && data.error) {
            // setError(data.error);
            console.log(data.error);
          } else {
            setProducts(
              data &&
              data.results &&
              data.results.map((slide, index) => {
                return (
                  <SwiperSlide key={index} className="rounded h-100">
                    <TechnicCard
                      product={slide}
                      reloadCartNo={reloadCartNo}
                      setReloadCartNo={setReloadCartNo}
                      home={banners}
                    />
                  </SwiperSlide>
                );
              })
            );
          }
        });
      }
    };
    loadFirstProducts();
  }, [firstNavbar, banners, reloadCartNo]);

  return (
    <Base
      title=""
      description="Welcome To Technic store"
      className="container"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
    >
      <div>
        <ResponsiveCarousel banners={banners} />
        <div className="row d-flex justify-content-center my-5">
          {firstNavbar &&
            firstNavbar.children &&
            firstNavbar.children.map((navbar, index) => {
              if (index >= 6) {
                return "";
              }
              return (
                <article key={index} className="col-6 col-sm-4 col-lg-2">
                  <Link
                    to={String("/" + String(navbar.parent__text_en || navbar.parent__text) + "/" + String(navbar.text_en || navbar.text) + "/" + String(navbar.id)).replace(/\s+/g, "-").toLowerCase()}
                    className="cat-block"
                  >
                    <figure>
                      <img
                        src={(navbar.image && API_URL + "media/" + navbar.image) || default_image}
                        alt={navbar && navbar.text}
                      />
                    </figure>
                    <h4 className="cat-block-title">{navbar && navbar.text}</h4>
                  </Link>
                </article>
              );
            })}
        </div>

        <Head title={firstNavbar && firstNavbar.text && firstNavbar.text + " جدید"} />

        <div className="row mx-auto">
          <CarouselInline items={products} count={6} />
        </div>

        <div className="row mx-auto mt-5">
          <div className="col-12 col-sm-12 col-lg-6 m-auto mb-5 mb-lg-0">
            <Link to={banners && banners.banner_3_link}><img src={banners && banners.banner_3} width="100%" alt="" className="round-radius m-auto" /></Link>
          </div>
          <div className="col-12 col-sm-12 col-lg-6 m-auto">
            <Link to={banners && banners.banner_4_link}><img src={banners && banners.banner_4} width="100%" alt="" className="round-radius m-auto" /></Link>
          </div>
        </div>

        <div className="row mx-auto mt-5">
          <hr className="mt-5" />
          <CarouselInline items={brands} count={8} navigation_value={true}/>
        </div>

        <Head title="کالاهای محبوب" />

        <div className="row m-auto">
          <CarouselInline items={popularProducts} count={6} />
        </div>

        <div className="row mx-auto mt-5">
          <div className="col-12">
            <Link to={banners && banners.banner_5_link}><img src={banners && banners.banner_5} width="100%" alt="" className="round-radius" /></Link>
          </div>
        </div>

        <Head title="تخفیف ویژه!" />
        <div className="row text-center m-auto">
          <div className="col-12 col-sm-12 col-lg-7 py-auto m-auto">
            <div className="row">
              {specialProducts &&
                specialProducts.map((slide, index) => {
                  return (
                    <div key={index} className="col-12 col-sm-12 col-lg-4 text-center mb-3 mx-auto">
                      <TechnicCard
                        product={slide}
                        reloadCartNo={reloadCartNo}
                        setReloadCartNo={setReloadCartNo}
                        home={banners}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-5 py-auto m-auto">
            <Link to={banners && banners.banner_6_link}><img src={banners && banners.banner_6} alt="" className="round-radius w-100 mt-5" /></Link>
          </div>
        </div>
        <Head title="واحد نرم‌افزار مجموعه تکنیک" />
        <div className="row text-center m-auto">
          <div className="col-12 col-sm-12 col-lg-5 py-auto m-auto">
            <h1>VST, Plugins, Samples</h1>
            <br />
            <h3 className="text-center mb-5">
              به‌روزترین آرشیو نرم‌افزارهای موسیقی را<br/>از تیم نرم‌افزار مجموعه تکنیک بخواهید.
            </h3>
            <Link className="btn btn-primary btn-lg" aria-label="Link" to="/products">
              <span>مشاهده پکیج‌ها ←</span>
            </Link>
          </div>
          <div className="col-12 col-sm-12 col-lg-7 py-auto m-auto">
            <div className="row">
              {softwareProducts &&
                softwareProducts.map((slide, index) => {
                  return (
                    <div key={index} className="col-12 col-sm-12 col-lg-4 text-center mb-3 mx-auto">
                      <TechnicCard
                        product={slide}
                        reloadCartNo={reloadCartNo}
                        setReloadCartNo={setReloadCartNo}
                        home={banners}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <Head title="پیشنهاد تکنیک" />
        <div className="row text-center m-auto">
          <div className="col-12 col-sm-12 col-lg-7 py-auto m-auto">
            <div className="row">
              {suggestedProducts &&
                suggestedProducts.map((slide, index) => {
                  return (
                    <div key={index} className="col-12 col-sm-12 col-lg-4 text-center mb-3 mx-auto">
                      <TechnicCard
                        product={slide}
                        reloadCartNo={reloadCartNo}
                        setReloadCartNo={setReloadCartNo}
                        home={banners}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-5 py-auto m-auto">
          <Link to={banners && banners.banner_7_link}><img src={banners && banners.banner_7} alt="" className="round-radius w-100 mt-3" /></Link>
          </div>
        </div>
        <Head title="آکوستیک تخصصی" />
        <p className="text-center w-100 m-auto">
          یکی از مهمترین زیرساخت های هر استودیو آکوستیک (acoustic) آن است. این مبحث بسیار گسترده و علمی است. مشاوره برای
          طراحی و نصب بر اساس نیازهای مشتری و اندازه گیری نهایی، جنبه های مهم فعالیت مهندسان آکوستیک مرکز تکنیک هستند.
          پس از مطالعه فضا، ما چندین راه حل بر اساس شرایط و بودجه مشتری به وی پیشنهاد می کنیم. ما اعتقاد داریم که هر
          پروژه بخشی از رزومه ما می شود، بنابراین نهایت تلاش خود را کرده و کیفیت نهایی پروژه را تضمین می کنیم. تکنیک
          بعنوان یکی از تخصصی ترین و علمی ترین مراکز آکوستیک در ایران شناخته می شود. پروژه هایی که در زیر مشاهده می کنید
          بخشی از افتخارات تکنیک در زمینه ی آکوستیک سالن های اجرا، سالن های سینما، استودیوهای صدا و تصویر، و غیره می
          باشد.
        </p>
        <Link to='/projects'>
          <img src={banners && banners.banner_8} alt="" className="round-radius w-100 mt-5" />
        </Link>

        <div className="row mx-auto mt-5">
          <hr className="mt-5" />
          <CarouselInline items={organs} count={8} navigation_value={true} />
        </div>

        <Head title="مقالات" />
        <div className="row mx-auto my-5">
          {blogs &&
            blogs.map((blog, index) => {
              return BlogCard(blog, index);
            })}
        </div>
      </div>
    </Base>
  );
}
