import React, { useState, useEffect } from "react";
import Base from "./Base";
import { getBlog } from "./helper/blogsHelper"
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function Blog() {
  const [reloadCartNo, setReloadCartNo] = useState(false);
  const [blog, setBlog] = useState({})

  const params = useParams();


  useEffect(() => {
    getBlog(params.id).then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setBlog(data);
      }
    })
    window.scrollTo(0, 0)
    return () => {}
  }, [params.id])
  

  return (
    <Base
      title="مقاله"
      description="Blog"
      className="container"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
      extra={<Link to="/blogs/" className="my-auto"> / مقالات</Link>}
    >
      <div ><img src={blog.post_image} alt={blog.title} className="w-100 m-auto"/></div>
      <div className="text-start h1 mt-5"><strong>{ blog.title }</strong></div>
      <div className="text-start display-5 my-5" style={{ "fontFamily": "persian" }} dangerouslySetInnerHTML={{ __html: blog.description }} />
      
    </Base>
  );
}

export default Blog;
