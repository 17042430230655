import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Base from "./Base";
import TechnicCard from "./TechnicCard";
import { getProducts } from "./helper/productsHelper";
import PaginatedItems from "./Pagination";
// import ReactDOM from 'react-dom';
import ProductFilter from "./ProductsFilter";
import ProductOrder from "./ProductOrder";
import UseParamToSearchParam from "./UseParamToSearchParam";
import { getHome } from "./helper/homeHelper";
import { Link } from "react-router-dom";
import { getCategoryFilter } from "./helper/categoryHelper";

const Products = () => {
  const params = useParams();
  const paramsString = UseParamToSearchParam()
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const [filters] = useState("category__id=0");
  const [title, setTitle] = useState("کالاها");
  const [reloadCartNo, setReloadCartNo] = useState(false);
  const [productBooleanFilters, setProductBooleanFilters] = useState([false, false, false, false]);
  const [checked, setChecked] = useState(params.brand__id != null ? [Number(params.brand__id)] : []);
  const [expanded, setExpanded] = useState([]);

  const [categoryChecked, setCategoryChecked] = useState(params.category__id != null ? [Number(params.category__id)] : []);
  const [categoryExpanded, setCategoryExpanded] = useState([]);

  const [productOrder, setProductOrder] = useState("order");
  const [home, setHome] = useState([]);
  const [categoryNodes, setCategoryNodes] = useState([]);


  useEffect(() => {
      setOffset(0);
      getCategoryFilter(`?offset=0&limit=2000&${paramsString}`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setCategoryNodes(data);
        }
      });

      if (params.category__text_en) {
        setTitle(params.category__text_en);
      } else {
        setTitle("کالاها");
      }
  
    return () => {}
  }, [params, paramsString])
  

  useEffect(() => {
 
    setCategoryChecked(params.category__id != null ? [Number(params.category__id)] : [])
    setChecked(params.brand__id != null ? [Number(params.brand__id)] : [])
    
  }, [categoryNodes, params.brand__id, params.category__id]);

  useEffect(() => {
    const loadHome = () => {
      getHome().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setHome(data && data.count && data.results[0]);
        }
      });
    };
    loadHome();

    return () => {};
  }, []);

  let cat_id = params.category__id
  useEffect(() => {
    const loadProducts = () => {
      const [a, b, c, d] = productBooleanFilters;
      getProducts(
        `?offset=${offset}&limit=${limit}&${a && "special_sale=" + a}&${params.value && "search=" + params.value}&${b && "cost_in_off__gt=0"}&${
          c && "product_state=" + c
        }&${d && "new=" + d}&${checked.length > 0 && "product_brand__id__in=" + checked}&${
          "category__id__in=" +
          (categoryChecked.length > 0
            ? categoryChecked
            : cat_id != null
            ? [Number(cat_id)]
            : [null])
        }&ordering=-have_count,${productOrder}`
      ).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setProducts(data && data.results);
          setLength(data && data.results && data.results.length);
          setCount(data && data.count);
        }
      });
    };
    loadProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, filters, productBooleanFilters, productOrder, checked, categoryChecked]);

  return (
    <Base
      title={title}
      description="Welcome To Products"
      className="container"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
      extra={
        <Link to="/products/" className="my-auto">
          {" "}
          / محصولات
        </Link>
      }
    >
      <div className="row">
        <div className="col-12 col-sm-4 col-md-2">
          <ProductFilter
            productBooleanFilters={productBooleanFilters}
            setProductBooleanFilters={setProductBooleanFilters}
            checked={checked}
            setChecked={setChecked}
            expanded={expanded}
            setExpanded={setExpanded}
            categoryChecked={categoryChecked}
            setCategoryChecked={setCategoryChecked}
            categoryExpanded={categoryExpanded}
            setCategoryExpanded={setCategoryExpanded}
            categoryNodes={categoryNodes}
          />
        </div>
        <div className="col-12 col-sm-8 col-md-10">
          <div className="row m-auto">
            <div className="col-12 m-auto">
              <div className="row m-auto">
                <div className="col-12 col-sm-6 col-lg-3 text-center p-3">
                  <ProductOrder productOrder={productOrder} setProductOrder={setProductOrder} />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 text-center p-3">
                  
                </div>
                <div className="col-12 col-sm-6 col-lg-3 text-center p-3">
                  
                </div>
                <div className="col-12 col-sm-6 col-lg-3 text-end py-3 persian float-end">
                  نمایش {length} از {count} کالا
                </div>
              </div>
            </div>
            <div className="row">
              {products &&
                products.map((product, index) => {
                  return (
                    <div key={index} className="col-12 col-sm-6 col-lg-3 py-3 text-center border-bottom">
                      {/* <Card product={product} /> */}
                      <TechnicCard
                        product={product}
                        reloadCartNo={reloadCartNo}
                        setReloadCartNo={setReloadCartNo}
                        home={home}
                        key={index}
                      />
                    </div>
                  );
                })}
            </div>
            
          </div>
        </div>
      </div>

      <br />
      <div id="paginate" dir="ltr">
        <PaginatedItems count={count} limit={limit} setLimit={setLimit} offset={offset} setOffset={setOffset} />
      </div>
    </Base>
  );
};

export default Products;
