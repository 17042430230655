import { API_ROOT } from "../../backend";

export const UserInfo = async () => {
    try {
      const response = await fetch(`${API_ROOT}UserInfo`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      return await response.json();
    } catch (err) {
      return console.log(err);
    }
  };

export const DataByUrl = async (url) => {
    try {
      const response = await fetch(`${url}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      return await response.json();
    } catch (err) {
      return console.log(err);
    }
  };

export const States = async () => {
    try {
      const response = await fetch(`${API_ROOT}States/get_states`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      return await response.json();
    } catch (err) {
      return console.log(err);
    }
  };

export const City = async (id) => {
    try {
      const response = await fetch(`${API_ROOT}States/get_city/?id=${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      return await response.json();
    } catch (err) {
      return console.log(err);
    }
  };

export const UserAddress = async (method, info={}) => {


    try {
      let send_data = {
        method: method,
        headers:{ Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,}
      }
      info.user = `${API_ROOT}User/${JSON.parse(localStorage.getItem("jwt")).user.id}/`
      if (method !== 'GET'){
        send_data['body'] = JSON.stringify(info)
      }
      let url = `${API_ROOT}UserAddress`
      if (info.url){
        url = info.url
      }
      if (method === 'POST'){
        url += '/'
      }
      const response = await fetch(`${url}`, send_data);
      if ((response.status !== 201 && response.status !== 200) || method === 'GET'){
        return await response.json();
      }
      return false;
    } catch (err) {
      return console.log(err);
    }
  };

export const UserInfoUpdate = async (user) => {
    try {
      const response = await fetch(`${user.url}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,
          
        },
        body: JSON.stringify(user),
      });
      return await response.json();
    } catch (err) {
      return console.log(err);
    }
  };
