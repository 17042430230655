
import Form from "react-bootstrap/Form";
import React from "react";

function ProductOrder({ productOrder, setProductOrder }) {



  return (
    <>
          <Form.Select size="lg" id="product_order" value={productOrder} onChange={e => {
            setProductOrder(e.target.value);
          }}>
            <option value='order'>مرتب سازی بر اساس</option>
            <option value="-final_price">گرانترین</option>
            <option value="final_price">ارزانترین</option>
            <option value="-created_at">جدیدترین</option>
            <option value="created_at">قدیمی ترین</option>
            <option value="view_counter">کمترین بازدید</option>
            <option value="-view_counter">بیشترین بازدید</option>
          </Form.Select>
    </>
  );
}

export default ProductOrder;
