import React, { useState, useEffect } from "react";

import { loadOrder } from "./helper/cartHelper";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";

function Orders() {
  const [myOrders, setMyOrders] = useState([]);

  useEffect(() => {
    loadOrder()
      .then((data) => {
        if (data.results) {
          setMyOrders(data.results);
        }
      })
      .catch((e) => console.log(e));

    return () => {};
  }, []);

  return (
    <div className="row">
      <div className="bg-light w-100 col-12 row mx-auto rounded my-3">
        <div className="col">شناسه خرید</div>
        <div className="col">مبلغ</div>
        <div className="col">شماره کارت</div>
        <div className="col">شماره پیگیری</div>
      </div>
      {myOrders &&
        myOrders.map((a, index) => {
          return (
            <Link
              to={"/user/dashboard/order/details/" + String(a.id)}
              key={index}
              className="bg-info w-100 col-12 row mx-auto rounded my-3 text-dark"
            >
              <div className="col">{a.id}</div>
              <div className="col">
                {" "}
                <NumericFormat
                  value={a.total_cost}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  displayType="text"
                  renderText={(value) => <b>{value}</b>}
                />{" "}
                تومان
              </div>
              <div className="col" dir="ltr" >{a.pay_card_number}</div>
              <div className="col">{a.track_number}</div>
            </Link>
          );
        })}
    </div>
  );
}

export default Orders;
