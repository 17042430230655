import React, { useState, useEffect } from "react";

import { loadOrderDetails } from "./helper/cartHelper";
import { Link } from "react-router-dom";
import Base from "../core/Base";
import { useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";

function OrdersDetails() {
  const [myOrdersDetails, setMyOrdersDetails] = useState([]);
  const params = useParams();

  useEffect(() => {
    loadOrderDetails(params.order__id)
      .then((data) => {
        if (data.results) {
          setMyOrdersDetails(data.results);
        }
      })
      .catch((e) => console.log(e));

    return () => {};
  }, [params.order__id]);

  return (
    <Base title="سفارش" description="this is user dashboard" extra={
      <Link to="/user/dashboard" className="my-auto">
        {" "}
        / حساب کاربری
      </Link>
    }> 
      <div className="row">
        <div className="bg-light w-100 col-12 row mx-auto rounded my-3">
          <div className="col">شناسه کالا</div>
          <div className="col">نام</div>
          <div className="col">name</div>
          <div className="col">تعداد</div>
          <div className="col">مبلغ</div>
        </div>
        {myOrdersDetails &&
          myOrdersDetails.map((a, index) => {
            return (
              <div
                key={index}
                className="bg-info w-100 col-12 row mx-auto rounded my-3 text-dark"
              >
                <div className="col">{a.id}</div>
                <div className="col">{a.product.name}</div>
                <div className="col">{a.product.name_en}</div>
                <div className="col">{a.count}</div>
                <div className="col">   <NumericFormat
                  value={a.cost_all}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  displayType="text"
                  renderText={(value) => <b>{value}</b>}
                />{" "}
                تومان</div>
              </div>
            );
          })}
      </div>
    </Base>
  );
}

export default OrdersDetails;
