import React, { useState} from "react";
import { Navigate, Link } from "react-router-dom";

import Base from "../core/Base";
import { signIn, sendCode, authenticate, isAuthenticated } from "../auth/helper";

const SignIn = () => {
  const [values, setValues] = useState({
    mobile: "",
    code: "",
    loading: false,
  });
  const {mobile, code, loading } =
    values;

  const handleChange = (name) =>
    (event) => {
      setValues({ ...values, error: false, [name]: event.target.value });
    };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });

    signIn({ mobile, code })
      .then((data) => {
        console.log("DATA", data);
        if (data.token) {
          //let sessionToken = data.token;
          authenticate(data, () => {
            console.log("TOKEN ADDED");
            setValues({
              ...values,
              didRedirect: true,
            });
          });
        } else {
          setValues({
            ...values,
            loading: false,
          });
          alert(data.non_field_errors[0])
        }
      })
      .catch((e) => console.log(e));
  };

  const onSendCode = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });

    sendCode({ mobile, code })
      .then((data) => {
        console.log("DATA", data);
      })
      .catch((e) => console.log(e));
  };

  const performRedirect = () => {
    if (isAuthenticated()) {
      return <Navigate to="/" />;
    }
  };

  const loadingMessage = () => {
    return (
      loading && (
        <div className="alert alert-info">
          <h2>Loading...</h2>
        </div>
      )
    );
  };


  const signInForm = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <form>
            <div className="form-group">
              <label className="text-dark">تلفن همراه</label>
              <input
                name="mobile"
                className="form-control"
                value={mobile}
                onChange={handleChange("mobile")}
                type="text"
              />
            </div>
            <div className="form-group">
              <label className="text-dark">کد ورود</label>
              <input
                name="code"
                className="form-control"
                value={code}
                autoComplete=""
                onChange={handleChange("code")}
                type="code"
              />
            </div>

            <button
              onClick={onSubmit}
              className="btn btn-success btn-lg btn-block mt-3"
            >
              ورود
            </button>

            <button
              onClick={onSendCode}
              className="btn btn-info btn-lg btn-block mt-3 ms-3"
            >
              ارسال کد ورود
            </button>

            <Link className="ms-3 mt-3 btn btn-lg btn-primary btn-block" to="/sign-up">
                        ثبت نام
                      </Link>
          </form>
        </div>
      </div>
    );
  };

  return (
    <Base title="ورود" description="Technic store">

      {loadingMessage()}

      {signInForm()}
      {/* <p className="text-center">
        {JSON.stringify(values)}
      </p> */}
      {performRedirect()}
    </Base>
  );
};

export default SignIn;
