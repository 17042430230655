import React, { useState, useEffect } from "react";
import ImageHelper from "./helper/ImageHelper";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { removeItemFromCart } from "./helper/cartHelper";
import { ProductCost } from "./TechnicCard";
import { getHome } from "./helper/homeHelper";


const CartProductView = ({
  product,
  reloadCartNo = false,
  setReloadCartNo = (f) => f,
  showAddToCart = true,
  removeFromCart = false,
}) => {
  const [home, setHome] = useState([]);
  const pId = product ? product.id : 0;
  showAddToCart =
    home.disable_cart || product.call_for_order || (home.not_display_price_non_existent && product.product_count === 0)
      ? false
      : showAddToCart;

  useEffect(() => {
    const loadHome = () => {
      getHome().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setHome(data && data.count && data.results[0]);
        }
      });
    };
    loadHome();
  }, []);

  return (
    <>
      <div className="col-2 m-auto">
        <Link to={String("/"+String(product.brand_names)+'-'+String(product.name_en) +"/"+String(pId)).replace(/\s+/g, "-").toLowerCase()}>
          <ImageHelper product={product} />
        </Link>
      </div>
      <div className="col m-auto">

          <div>{product.name_en}</div>
          <div>{product.name}</div>

      </div>
      <div className="col-2 m-auto">
      <ProductCost product_obj={product} home_obj={home} />
      </div>
      <div className="col-1 m-auto">1</div>
      <div className="col-2 m-auto"><ProductCost product_obj={product} home_obj={home} /></div>
      <div className="col-1 m-auto">
        <button
          onClick={() => {
            removeItemFromCart(removeFromCart, reloadCartNo,setReloadCartNo,reloadCartNo,setReloadCartNo);
            console.log("Product removed from cart");
          }}
          className="btn btn-block btn-danger my-2"
        >
          <MdDeleteForever className="h2" />
        </button>
      </div>
      <hr></hr>
      </>
  );
};

export default CartProductView;
