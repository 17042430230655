import { API } from "../../backend";

export const getProduct = async (id) => {
  try {
    if (id) {
      const response = await fetch(`${API}Products/${id}/`, { method: "GET" });
      return await response.json();
    }
  } catch (err) {
    return console.log(err);
  }
};

export const getProductByLink = async (link) => {
  try {
    const response = await fetch(link, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const getSearchProduct = async () => {
  try {
    const response = await fetch(`${API}Products/product_search/?offset=0&limit=10000&search=`, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};
