import { API } from "../../backend";

const getQuestion = async () => {
  try {
    const response = await fetch(`${API}Question/?limit=1000&offset=0`, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export default getQuestion;
