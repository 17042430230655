import React, { useState, useEffect } from "react";
import Base from "./Base";

import { loadCart, getCartMaster } from "./helper/cartHelper";
// import PaymentB from "./PaymentB";
import CartProductView from "./CartProductView";
import PaginatedItems from "./Pagination";
import PaymentForm  from "./PaymentForm"



const Cart = () => {
  const [carts, setCarts] = useState([]);
  const [reloadCartNo, setReloadCartNo] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const [total_cost, setTotalCost] = useState(0);
  const [orderId, setOrderId] = useState(0);

  useEffect(() => {
    loadCart(`&offset=${offset}&limit=${limit}`).then((data) => {
      if (data && data.error) {
        // setError(data.error);
        console.log(data.error);
      } else {
        setCarts((data && data.results) || []);
        setLength(data && data.results.length);
        setCount(data && data.count);
      }
    });
    getCartMaster().then((data) => {
      if (data && data.error) {
        // setError(data.error);
        console.log(data.error);
      } else {
        if (data && data.results) {
          setTotalCost(data.results[0] && data.results[0].total_cost);
          setOrderId(data.results[0] && data.results[0].id);
        }
      }
    });
  }, [reloadCartNo, offset, limit]);

  const loadAllCarts = (carts) => {
    return (
      <div className="col-12 row m-auto font-x">
        <div className="row m-auto">
          {" "}
          <div className="col-2">تصویر</div>
          <div className="col">کالا</div>
          <div className="col-2">قیمت</div>
          <div className="col-1">تعداد</div>
          <div className="col-2">جمع کل</div>
          <div className="col-1">حذف</div>
          <hr></hr>
        </div>
        {carts &&
          carts.map((cart, index) => {
            return (
              <div className="row m-auto" key={index}>
                <CartProductView
                  product={cart.product}
                  reloadCartNo={reloadCartNo}
                  setReloadCartNo={setReloadCartNo}
                  showAddToCart={false}
                  removeFromCart={cart.id}
                />
              </div>
            );
          })}
      </div>
    );
  };

  // const loadCheckout = () => {
  //   return (
  //     <div>
  //       <h1>Checkout</h1>
  //     </div>
  //   );
  // };

  return (
    <Base
      title="سبد خرید"
      description="Welcome to checkout"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
    >
      <div className="row text-center m-auto">
        <div className="m-auto">
          نمایش {length} از {count} کالا
        </div>
        <div className="col-12 col-lg-8 row">{carts && carts.length > 0 ? loadAllCarts(carts) : <h4>...</h4>}</div>

        <div className="col-12 col-lg-4 text-center cart-detail rounded h-100 mx-auto">
        <PaymentForm count={count} total_cost={total_cost}  orderId={orderId}/>
          
        </div>
        <br />
        <div id="paginate">
          <PaginatedItems count={count} limit={limit} setLimit={setLimit} offset={offset} setOffset={setOffset} />
        </div>
      </div>
    </Base>
  );
};

export default Cart;
