import React, { useState, useEffect } from "react";
import getPages from "./helper/pagesHelper";
import Base from "./Base";

export default function Pages({ page }) {
  const [send_order, setSendOrder] = useState("");
  const [save_order, setSaveOrder] = useState("");
  const [bank_account, setBankAccount] = useState("");
  const [about_us, setAboutUs] = useState("");
  const [guaranty, setGuaranty] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [questions, setQuestions] = useState("");
  const [call_us, setCallUs] = useState("");
  const [leasing, setLeasing] = useState("");
  useEffect(() => {
    const loadFirstProducts = () => {
      getPages().then((data) => {
        if (data && data.error) {
          console.log(data.error);
        } else {
          // setPages(data && data.results);
          if (data && data.results) {
            const res = data.results[0];
            setSendOrder(res.send_order);
            setSaveOrder(res.save_order);
            setBankAccount(res.bank_account);
            setAboutUs(res.about_us);
            setGuaranty(res.guaranty);
            setPrivacy(res.privacy);
            setQuestions(res.questions);
            setCallUs(res.call_us);
            setLeasing(res.leasing);
          }
        }
      });
    };
    loadFirstProducts();
  }, []);
  if (page === "send_order") {
    return <Base title="ارسال سفارش" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: send_order }} /></Base>;
  }
  if (page === "save_order") {
    return <Base title="ثبت سفارش" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: save_order }} /></Base>;
  }
  if (page === "bank_account") {
    return <Base title="شماره حساب" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: bank_account }} /></Base>;
  }
  if (page === "about_us") {
    return <Base title="درباره ما" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: about_us }} /></Base>;
  }
  if (page === "guaranty") {
    return <Base title="ضمانت" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: guaranty }} /></Base>;
  }
  if (page === "privacy") {
    return <Base title="شرایط و حریم خصوصی" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: privacy }} /></Base>;
  }
  if (page === "questions") {
    return <Base title="سوالات متداول" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: questions }} /></Base>;
  }
  if (page === "contact") {
    return <Base title="تماس با ما" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: call_us }} /></Base>;
  }
  if (page === "leasing") {
    return <Base title="فروش اقساطی" description="Welcome to AccountNumber"><div dangerouslySetInnerHTML={{ __html: leasing }} /></Base>;
  }
}
