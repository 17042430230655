import React, { useState, useEffect } from "react";
import { getSlider } from "./helper/sliderHelper";
import { Link } from "react-router-dom"
// import { getHome } from "./helper/homeHelper";
// import { default_image } from "../backend";

import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

const ResponsiveCarousel = ({ banners }) => {
  const [sliders, setSliders] = useState([]);
  // const [banners, setBanner] = useState([]);

  useEffect(() => {
    const loadSlider = () => {
      getSlider().then((data) => {
        if (data && data.error) {
          console.log(data.error);
        } else {
          setSliders(data && data.results);
        }
      });
    };
    loadSlider();
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-lg-8 mt-3">
        {/* https://swiperjs.com/react */}
        <Swiper className="h-100"
          modules={[Navigation, Pagination, A11y, Autoplay]}
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-navigation-size": "25px",
          }}
          dir="rtl"
          spaceBetween={10}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          // centeredSlides={true}
          loop={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          {sliders && sliders.map((slide, index) => {
            return (
              <SwiperSlide key={index} className="round-radius">
                <img src={slide.photo} className="round-radius w-100 h-100"  alt={slide.header} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="col-12 col-sm-12 col-lg-4 mt-3">
        <div className="row h-100">
          <div className="col-12 h-50 pb-3 m-auto">
            <Link to={banners && banners.banner_1_link}><img src={banners && banners.banner_1} width="100%" alt="" className="round-radius h-100 mw-100 mh-100" /></Link>
          </div>
          <div className="col-12 h-50 m-auto">
          <Link to={banners && banners.banner_2_link}><img src={banners && banners.banner_2} width="100%" alt="" className="round-radius h-100 mx-auto mw-100 mh-100" /></Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCarousel;
