import React, { useEffect, useState } from "react";
import Base from "../core/Base";
import { GetAboutUs } from "./helper/aboutUsHelper";
import { default_image } from "../backend";
import { Link } from "react-router-dom";
import Head from "../core/Head";

function AboutUs() {
  const [about_us, setAboutUs] = useState({
    name: "",
    image_main: "",
    main_title: "",
    main_description: "",
    first_title: "",
    first_desc: "",
    second_title: "",
    second_desc: "",
    third_title: "",
    third_desc: "",
    image_1: "",
    image_2: "",
    title_1: "",
    desc_1: "",
    brand_title: "",
    brand_desc: "",
    brands: "",
    personnel_title: "",
    personnel: "",
    brands_info: "",
    personnel_info: "",
  });

  useEffect(() => {
    GetAboutUs()
      .then((data) => {
        setAboutUs({
          name: data.results[0].name,
          image_main: data.results[0].image_main,
          main_title: data.results[0].main_title,
          main_description: data.results[0].main_description,
          first_title: data.results[0].first_title,
          first_desc: data.results[0].first_desc,
          second_title: data.results[0].second_title,
          second_desc: data.results[0].second_desc,
          third_title: data.results[0].third_title,
          third_desc: data.results[0].third_desc,
          image_1: data.results[0].image_1,
          image_2: data.results[0].image_2,
          title_1: data.results[0].title_1,
          desc_1: data.results[0].desc_1,
          brand_title: data.results[0].brand_title,
          brand_desc: data.results[0].brand_desc,
          brands: data.results[0].brands,
          personnel_title: data.results[0].personnel_title,
          personnel: data.results[0].personnel,
          brands_info: data.results[0].brands_info,
          personnel_info: data.results[0].personnel_info,
        });
      })
      .catch((e) => console.log(e));
      window.scrollTo(0, 0)
    return () => {};
  }, []);

  return (
    <Base title="درباره ما" description="abut us page">
      <img src={about_us.image_main} alt="Main" className="w-100 m-auto" />
      <br />
      <div className="w-100 mx-auto mt-5">{about_us.main_title}</div>
      <div className="w-100 mx-auto mt-3" dangerouslySetInnerHTML={{ __html: about_us.main_description }} />
      <div className="row mt-5">
        <div
          className="col-12 col-sm-4"
          dangerouslySetInnerHTML={{ __html: about_us.first_title + about_us.first_desc }}
        />
        <div
          className="col-12 col-sm-4"
          dangerouslySetInnerHTML={{ __html: about_us.second_title + about_us.second_desc }}
        />
        <div
          className="col-12 col-sm-4"
          dangerouslySetInnerHTML={{ __html: about_us.third_title + about_us.third_desc }}
        />
      </div>
      <div className="row mt-5">
        <div className="col-lg-6 offset-lg-1">
          <div className="about-images m-auto">
            <img src={about_us.image_1} alt="front" className="about-img-front m-auto text-center" />
            <img src={about_us.image_2} alt="back" className="about-img-back" />
          </div>
        </div>
        <div className="col-lg-5 mb-3 mb-lg-0">
          <h2>{about_us.title_1}</h2>
          <div className="w-100" dangerouslySetInnerHTML={{ __html: about_us.desc_1 }} />
        </div>
      </div>

      <div className="row align-items-center mt-5">
        <div className="col-lg-5">
          <div className="brands-text">
            <h2 className="title">{about_us.brand_title}</h2>
            <p dangerouslySetInnerHTML={{ __html: about_us.brand_desc }} />
          </div>
        </div>
        <div className="col-lg-7">
          <div className="brands-display">
            <div className="row justify-content-center">
              <div className="row m-auto">
                {about_us.brands_info &&
                  about_us.brands_info.map((brand, index) => {
                    return (
                      <div className="col-sm-4 col-md-4 col-lg-3 justify-content-center" key={index}>
                        <div className="col-sm-4 col-md-4 col-lg-3 justify-content-center">
                          <Link className="brand" aria-label="Link" to={"#"}>
                            <img src={brand.logo || default_image} alt="brand.name" />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Head title={about_us.personnel_title} />
      <div className="row">
      {about_us.personnel_info &&
        about_us.personnel_info.map((prs, index) => {
          return(
          <div className="col-sm-4 col-md-3 col-lg-2" key={index}>
            <div className="member member-anim text-center">
              <figure className="member-media">
                <img src={prs.profile_image || default_image} alt={prs.position} />
                <figcaption className="member-overlay">
                  <div className="member-overlay-content">
                    <h3 className="member-title">
                      {prs.first_name +" "+prs.last_name}
                      <span>{prs.position}</span>
                    </h3>
                  </div>
                </figcaption>
              </figure>
              <div className="member-content">
                <h3 className="member-title">
                {prs.first_name +" "+prs.last_name}<span>{prs.position}</span>
                </h3>
              </div>
            </div>
          </div>);
        })}
      </div>
      
    </Base>
  );
}

export default AboutUs;
