import React, { useState, useEffect } from "react";
// import {useTranslation} from "react-i18next";
import "../styles.css";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import HomeNavbar from "./Navbar";
import { AiOutlineUser } from "react-icons/ai";
// https://react-icons.github.io/react-icons/
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from "./Footer";

import SearchBox from "./SearchBox";
import { useNavigate   } from "react-router-dom";

import { getCartCount } from "./helper/cartHelper";
import { getHome } from "./helper/homeHelper";
import CartQuickView from "./CartQuickView";

function BreadcrumbExample({ title, extra='' }) {
  if (title) {
    return (
      <Breadcrumb className="bg-light breadcrumb my-auto">
        <Link to="/" className="my-auto">صفحه اصلی </Link>{extra} <Breadcrumb.Item className="my-auto" active>{"  /  " + title}</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
}

const Base = ({
  title = "Technic Store",
  description = "Technic Store Description",
  reloadCartNo,
  setReloadCartNo,
  className = "p-4",
  extra = "",
  children,
}) => {
  // const {t, i18n} = useTranslation('common');
  const [cartCount, setCartCount] = useState(0);
  const [home, setHome] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("jwt"))) {
      const loadCartCount = () => {
        getCartCount().then((data) => {
          if (data && data.error) {
            // setError(data.error);
            console.log(data.error);
            setCartCount(0);
          } else {
            setCartCount((data && data.count) || 0);
          }
        });
      };
      loadCartCount();
    }

  }, [reloadCartNo]);

  useEffect(() => {
    const loadHome = () => {
      getHome().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setHome(data && data.count && data.results[0]);
        }
      });
    };
    loadHome();
  }, []);

  function handleKeyPress(e) {
    e.preventDefault();

    // ریدایرکت به صفحه‌ی دیگر
    navigate(String(`/search/${e.target.elements[1].value}`))
		}


  return (
    <div className="min-vh-100">
      {home.header_banner_message !== "" && (
        <div className="header-notification text-center text-white p-3 m-auto">{home.header_banner_message}</div>
      )}
      <Menu />
      <div className="container min-vh-100 text-center mx-auto my-4">
        <div>
          <form className="" onSubmit={handleKeyPress}>
            <div className="row mt-3">
              <div className="col-6 col-lg-3 order-lg-0 order-0">
                <div className="row">
                  <div className="col-lg-0 col-4 d-lg-none">
                    <span className="d-lg-none"><HomeNavbar /></span>
                  </div>
                  <div className="col-lg-3 col-4">
                    <Link className="nav-link" to="/user/dashboard">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          {" "}
                          <AiOutlineUser className="h2 font-dark-gray" />
                        </div>
                        <div className="col-12 h6 d-flex justify-content-center font-gray">حساب کاربری</div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3 col-4">
                    <Link className="nav-link" to="/cart">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div>
                            <CartQuickView
                              cartCount={cartCount}
                              reloadCartNo={reloadCartNo}
                              setReloadCartNo={setReloadCartNo}
                              home={home}
                            />
                          </div>
                        </div>
                        <div className="col-12 h6 d-flex justify-content-center font-gray">سبد خرید</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-lg-6 order-lg-1 order-2">
                <div className="row" id="searchBox">
                  <SearchBox />
                </div>
              </div>
              <div className="col-6 col-lg-3 order-lg-2 order-1">
                <Link className="nav-link me-2 d-flex justify-content-end" to="/">
                  <Logo width="170px" />
                  <img src="/favicon.ico" width="80px" height="80px" alt="" className="float-end d-lg-none mb-2" />
                </Link>
              </div>
            </div>
          </form>
        </div>

        <HomeNavbar MyClassName="d-none d-lg-block"/>
        
        <BreadcrumbExample title={title} extra={extra} />
        <div className={className}>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Base;
