import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
// import { Route, Link, Routes, BrowserRouter } from 'react-router-dom'
import PrivateRoutes from "./auth/helper/PrivateRoutes";
import SignIn from "./user/SignInUser";
import SignUp from "./user/SignUpPage";
import Cart from "./core/Cart";
// import Contact from "./core/Contact"
// import About from "./core/About"
// import Leasing from "./core/Leasing"
// import AccountNumber from "./core/AccountNumber"
import Pages from "./core/Pages";

import Questions from "./core/Question";
import Home from "./core/Home";
import Products from "./core/Products";
import Product from "./core/Product";
import Blog from "./core/Blog";
import Blogs from "./core/Blogs";
import Project from "./core/Project";
import Projects from "./core/Projects";

import Contact from "./core/Contact";
import OrdersDetails from "./core/OrdersDetails";

import UserDashboard from "./user/UserDashboard";
import AboutUs from "./user/AboutUs";

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/SamanCallBack" element={<Home />} /> */}
        <Route path="/products" element={<Products />} />
        <Route path="/search/:value" element={<Products />} />
        <Route path="/brands/:brand__english_name/:brand__id" element={<Products />} />
        {/* <Route path="/products/:category__text_en/:category__id" element={<Products />} /> */}
        <Route path="/:category__parent__text_en/:category__text_en/:category__id" element={<Products />} />
        {/* <Route path="/product/:brand_names/:name/:id/" element={<Product />} /> */}
        <Route path="/:brand_product_names/:id/" element={<Product />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/cart" element={<PrivateRoutes Component={Cart} />} />
        <Route path="/contact" element={<Contact />} />
        {/*<Route path="/about" element={<About />} />
                <Route path="/leasing" element={<Leasing />} />
                <Route path="/account-number" element={<AccountNumber />} /> */}
        <Route path="/send_order" element={<Pages page="send_order" />} />
        <Route path="/save_order" element={<Pages page="save_order" />} />
        <Route path="/bank_account" element={<Pages page="bank_account" />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/blog/:url_addr/:title/:id" element={<Blog />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/project/:url_addr/:title/:id" element={<Project />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/guaranty" element={<Pages page="guaranty" />} />
        <Route path="/privacy" element={<Pages page="privacy" />} />
        <Route path="/questions" element={<Questions />} />
        {/* <Route path="/contact" element={<Pages page="contact" />} /> */}
        <Route path="/leasing" element={<Pages page="leasing" />} />

        {/* نمونه جهت استفاده برای یوآرال هایی که نیاز به لاگین دارن */}
        <Route path="/user/dashboard" element={<PrivateRoutes Component={UserDashboard} />} />
        <Route path="/user/dashboard/order/details/:order__id" element={<PrivateRoutes Component={OrdersDetails} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
