import React from "react";
import Form from "react-bootstrap/Form";
import BrandsFilter from "./BrandsFilter";
import CategoryFilter from "./CategoryFilter";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";


function OffCanvasFilter({ name, ...props }) {
  const [special_sale_switch, has_off_switch, product_state, vitrin_switch] = props.productBooleanFilters;
  const aSwitchClick = () => {
    props.setproductbooleanfilters([!special_sale_switch, has_off_switch, product_state, vitrin_switch]);
  };
  const bSwitchClick = () => {
    props.setproductbooleanfilters([special_sale_switch, !has_off_switch, product_state, vitrin_switch]);
  };

  const dSwitchClick = () => {
    props.setproductbooleanfilters([special_sale_switch, has_off_switch, product_state, !vitrin_switch]);
  };

  return (
    <div>
      <div>
        <div className="text-start">
          <Form.Check // prettier-ignore}
            type="switch"
            id="special_sale_switch"
            label="فروش ویژه"
            checked={special_sale_switch}
            onClick={aSwitchClick}
          />
          <Form.Check // prettier-ignore
            type="switch"
            id="has_off_switch"
            label="تخفیف‌ دار ها"
            checked={has_off_switch}
            onClick={bSwitchClick}
          />
          <Form.Check // prettier-ignore
            type="switch"
            id="vitrin_switch"
            label="جدید"
            checked={vitrin_switch}
            onClick={dSwitchClick}
          />
          <hr />
          <Form.Select
            size="lg"
            id="product_state"
            value={product_state}
            onChange={(e) => {
              props.setproductbooleanfilters([special_sale_switch, has_off_switch, e.target.value, vitrin_switch]);
            }}
          >
            <option value="">وضعیت کالا</option>
            <option value="new">نو</option>
            <option value="second_hand">دست دوم</option>
            <option value="vitrin">ویترینی</option>
          </Form.Select>
          <Accordion className="mt-3 text-start" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="my-auto text-start h4">دسته بندی ({props.categoryChecked.length})</div>
              </Accordion.Header>
              <Accordion.Body style={{maxHeight:'600px',overflow:'auto'}}>
                <CategoryFilter
                  checked={props.categoryChecked}
                  setChecked={props.setCategoryChecked}
                  expanded={props.categoryExpanded}
                  setExpanded={props.setCategoryExpanded}
                  categoryNodes={props.categoryNodes}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion className="mt-3 text-end" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="my-auto text-start h4">برند ها ({props.checked.length})</div>
              </Accordion.Header>
              <Accordion.Body dir="ltr" style={{maxHeight:'600px',overflow:'auto'}}>
                <BrandsFilter
                  checked={props.checked}
                  setChecked={props.setChecked}
                  expanded={props.expanded}
                  setExpanded={props.setExpanded}
                  categoryChecked={props.categoryChecked}
                  categoryExpanded={props.categoryExpanded}
                  categoryNodes={props.categoryNodes}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

function ProductFilter({
  productBooleanFilters,
  setProductBooleanFilters,
  checked,
  setChecked,
  expanded,
  setExpanded,
  categoryChecked,
  setCategoryChecked,
  categoryExpanded,
  setCategoryExpanded,
  categoryNodes,
}) {

  return (
    <div>
      <Button
        className="btn-danger my-3"
        onClick={() => {
          setCategoryChecked([]);
          setChecked([]);
        }}
      >
        حذف فیلتر ها
      </Button>

      {["start"].map((placement, idx) => (
        <OffCanvasFilter
          key={idx}
          placement={placement}
          name={"فیلتر"}
          productBooleanFilters={productBooleanFilters}
          setproductbooleanfilters={setProductBooleanFilters || ""}
          checked={checked}
          setChecked={setChecked}
          expanded={expanded}
          setExpanded={setExpanded}
          categoryChecked={categoryChecked}
          setCategoryChecked={setCategoryChecked}
          categoryExpanded={categoryExpanded}
          setCategoryExpanded={setCategoryExpanded}
          categoryNodes={categoryNodes}
        />
      ))}
    </div>
  );
}

export default ProductFilter;
