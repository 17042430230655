import { API } from "../../backend";

export const addItemToCart = (item) => {
  try {
    const response = fetch(`${API}OrderDetails/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,
      },
      body: JSON.stringify(item),
    });
    return response.then((res) => res.json());
  } catch (err) {
    console.log(err);
  }
};

export const getCartCount = () => {
  try {
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    const response = fetch(`${API}OrderDetails/cart_count/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + (jwt && jwt.token) || "",
      },
      //   body: JSON.stringify(item),
    });
    return response.then((res) => res.json());
  } catch (err) {
    console.log(err);
  }
};


export const getCartMaster = () => {
  try {
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    const response = fetch(`${API}Orders/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + (jwt && jwt.token) || "",
      },
      //   body: JSON.stringify(item),
    });
    return response.then((res) => res.json());
  } catch (err) {
    console.log(err);
  }
};


export const loadCart = (filter) => {
  try {
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    const response = fetch(`${API}OrderDetails/?order_state=basket${filter}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + (jwt && jwt.token) || '',
      },
      //   body: JSON.stringify(item),
    });
    return response.then((res) => res.json());
  } catch (err) {
    console.log(err);
  }
};


export const removeItemFromCart =  (productId,reload,setReload,reloadCartNo,setReloadCartNo) => {
  try {
     let jwt = JSON.parse(localStorage.getItem("jwt"))
     fetch(`${API}OrderDetails/${productId}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + (jwt && jwt.token) || '',
      },
      //   body: JSON.stringify(item),
    });
    setReload(!reload);
    setReloadCartNo(!reloadCartNo);
    alert('از سبد خرید حذف شد')
    return true;
  } catch (err) {
    return console.log(err);
  }
};

export const cartEmpty = (next) => {
  if (typeof window != "undefined") {
    localStorage.removeItem("cart");
    let cart = [];
    localStorage.setItem("cart", JSON.stringify(cart));
    next();
  }
};


export const loadOrder = (filter) => {
  try {
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    const response = fetch(`${API}Orders/?order_state=completed&${filter}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + (jwt && jwt.token) || '',
      },
      //   body: JSON.stringify(item),
    });
    return response.then((res) => res.json());
  } catch (err) {
    console.log(err);
  }
};


export const updateOrderAddr = (id,address) => {
  try {
    let send_data = {
      method: 'PUT',
      headers:{ Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + JSON.parse(localStorage.getItem("jwt")).token,},
      body : JSON.stringify({'address':address})
    }
    const response = fetch(`${API}Orders/${id}/`,send_data)
    return response.data
  } catch (error) {
    alert(error)
  }

}

export const loadOrderDetails = (id) => {
  try {
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    const response = fetch(`${API}OrderDetails/?order_state=completed&order__id=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + (jwt && jwt.token) || '',
      },
      //   body: JSON.stringify(item),
    });
    return response.then((res) => res.json());
  } catch (err) {
    console.log(err);
  }
};
