import { API } from '../../backend'

export const getSlider = async () => {
  try {
    const response = await fetch(`${API}Slider`, { method: 'GET' })
    return await response.json()
  } catch (err) {
    return console.log(err)
  }
}
