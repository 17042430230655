import React, { useState, useEffect } from 'react'
import { getHome } from './helper/homeHelper'
// import { default_image } from '../backend'

const Logo = ({ width }) => {

    const [logo, setLogo] = useState('/images/logo.svg')
    const [error, setError] = useState(false)

    const loadLogo =  () =>{
        getHome().then(data => {
            if (data && data.error) {
              setError(data.error)
              console.log(error)
            } else {
                setLogo(data && data.results[0].home_logo)
            }
          })
    }

    useEffect(() => {
        loadLogo()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    return (
        <img src={logo} width={width} alt="" className="float-end d-none d-lg-block" />
    )
}

export default Logo;