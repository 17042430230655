import React, { useState, useEffect } from "react";
import Base from "./Base";
import BlogCard from "./BlogCard";
import { getBlogs } from "./helper/blogsHelper";
import PaginatedItems from "./Pagination";


const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const [title] = useState("مقالات");
  const [reloadCartNo, setReloadCartNo] = useState(false);

  useEffect(() => {
    const loadBlogs = () => {
      getBlogs(`?offset=${offset}&limit=${limit}`).then((data) => {
        if (data && data.error) {
          console.log(data.error);
        } else {
          setBlogs(data && data.results);
          setLength(data && data.results && data.results.length);
          setCount(data && data.count);
        }
      });
    };
    loadBlogs();
  }, [offset, limit]);

  return (
    <Base
      title={title}
      description="Welcome To Blogs"
      className="container"
      reloadCartNo={reloadCartNo}
      setReloadCartNo={setReloadCartNo}
    >
      <div>
        نمایش {length} از {count} مقاله
      </div>

      <div className="row m-auto">
        {blogs &&
          blogs.map((blog, index) => {
            return <>{BlogCard(blog, index)}</>;
          })}
      </div>

      <br />
      <div id="paginate">
        <PaginatedItems count={count} limit={limit} setLimit={setLimit} offset={offset} setOffset={setOffset} />
      </div>
    </Base>
  );
};

export default Blogs;
