import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles.css'
// import App from './App'
import MyRoutes from './MyRoutes'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_en  from './i18n/en/common.json'
import common_fa  from './i18n/fa/common.json'
// import ChangeLanguageComponent from "./ChangeLanguage"

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'fa', // language to use
  resources: {
    en: {
      common: common_en // 'common' is our custom namespace
    },
    fa: {
      common: common_fa
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <I18nextProvider i18n={i18next}>
    {/* <ChangeLanguageComponent /> */}
    <MyRoutes />
  </I18nextProvider>
)
