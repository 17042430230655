import React, { useState } from "react";
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { signUp } from "../auth/helper";

const SignUp = () => {
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    error: "",
    success: false,
  });
  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
  });

  const { name, mobile, error, success } = values;

  const handleChange = (name) =>
    (event) => {
      setValues({ ...values, error: false, [name]: event.target.value });
    };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false });
    setErrors({});
    signUp({ name, mobile })
      .then((data) => {
        console.log("DATA", data);
        if (data.mobile === mobile) {
          setValues({
            ...values,
            name: "",
            mobile: "",
            error: "",
            success: true,
          });
          setErrors({});
        } else {
          setValues({
            ...values,
            error: true,
            success: false,
          });
          setErrors({...data });
          console.log(error)
        }
      })
      .catch((e) => console.log(e));
  };

  const successMessage = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <div
            className="alert alert-success"
            style={{ display: success ? "" : "none" }}
          >
            حساب کاربری جدید ایجاد شد. لطفا <Link
              to="/sign-in"
            >
              وارد شوید.
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const errorMessage = (message) => {
    return (

          <div
            className="alert alert-danger"
            style={{ display: message ? "" : "none" }}
          >
            {message}
          </div>

    );
  };

  const signUpForm = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-start">
          <form>
            <div className="form-group">
              <label className="text-dark">نام</label>
              {errorMessage(errors.name)}
              <input
                className="form-control"
                value={name}
                onChange={handleChange("name")}
                type="text"
              />
            </div>
            <div className="form-group">
              <label className="text-dark">تلفن همراه</label>
              {errorMessage(errors.mobile)}
              <input
                className="form-control"
                value={mobile}
                onChange={handleChange("mobile")}
                type="text"
              />
            </div>
            <button
              onClick={onSubmit}
              className="btn btn-success btn-lg btn-block mt-3"
            >
              ثبت نام
            </button>
            <Link className="btn btn-primary btn-lg btn-block mt-3 ms-3" to="/sign-in">
                        ورود
                      </Link>
          </form>
        </div>
      </div>
    );
  };

  return (
    <Base title="ثبت نام" description="A sign up for technic user">
      {successMessage()}
      {signUpForm()}
      {/* <p className="text-center">
        {JSON.stringify(values)}
      </p> */}
    </Base>
  );
};

export default SignUp;
