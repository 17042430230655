import { API } from '../../backend'

export const getHome = async () => {
  try {
    const response = await fetch(`${API}Home`, { method: 'GET' })
    return await response.json()
  } catch (err) {
    return console.log(err)
  }
}
