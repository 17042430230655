import React, { useState, useEffect } from "react";

import { loadCart, getCartMaster } from "./helper/cartHelper";
// import PaymentB from "./PaymentB";
import { TotalCostCart } from "./PaymentForm";
import { ProductCost } from "./TechnicCard";

import ImageHelper from "./helper/ImageHelper";
import { Link } from "react-router-dom";
import { removeItemFromCart } from "./helper/cartHelper";

import Dropdown from "react-bootstrap/Dropdown";

import { BsCart } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import Badge from "@mui/material/Badge";

const CartQuickView = ({ cartCount, reloadCartNo, setReloadCartNo, home }) => {
  const [carts, setCarts] = useState([]);
  const [limit] = useState(200);
  const [offset] = useState(0);
  // const [count, setCount] = useState(0);
  // const [length, setLength] = useState(0);
  const [total_cost, setTotalCost] = useState(0);
  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("jwt"))) {
      loadCart(`&offset=${offset}&limit=${limit}`).then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          setCarts((data && data.results) || []);
          // setLength(data && data.results.length);
          // setCount(data && data.count);
        }
      });
      getCartMaster().then((data) => {
        if (data && data.error) {
          // setError(data.error);
          console.log(data.error);
        } else {
          if (data && data.results) {
            setTotalCost(data.results[0] && data.results[0].total_cost);
          }
        }
      });
    }
  }, [cartCount, offset, limit]);

  return (
    <Dropdown
      variant="white"
      id="dropdown-basic"
      className="cart-quick-view"
      show={show}
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      <Badge badgeContent={cartCount} color="primary">
        <BsCart className="h2 font-dark-gray" />
      </Badge>
      <Dropdown.Menu>
        <div className="row text-wrap-cart mx-3">
          {carts &&
            carts.map((cart, index) => {
              return (
                <div className="col-12" key={index}>
                  <div className="row m-auto" style={{ height: "100px" }}>
                    <div className="col-10 m-auto">
                      <Dropdown.Item href={"/cart"}>
                        <div className="row">
                          <div className="col-4 m-auto">
                            <Link
                              to={
                                String("/" +
                                String(cart.product.brand_names) +
                                "-" +
                                String(cart.product.name_en) +
                                "/" +
                                String(cart.product.id)).replace(/\s+/g, "-").toLowerCase()
                              }
                            >
                              <ImageHelper product={cart.product} />
                            </Link>
                          </div>
                          <div className="col-8 m-auto text-wrap">
                            {cart.product.name}
                            <br />
                            <ProductCost product_obj={cart.product} home_obj={home} />
                          </div>
                        </div>
                      </Dropdown.Item>
                    </div>

                    <div className="col-2 m-auto align-middle">
                      <button
                        onClick={() => {
                          removeItemFromCart(cart.id, reloadCartNo, setReloadCartNo, reloadCartNo, setReloadCartNo);
                          console.log("Product removed from cart");
                        }}
                        className="btn btn-block btn-danger align-middle"
                      >
                        <MdDeleteForever className="h4 align-middle" />
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
        </div>
        <div style={{ minHeight: "40px" }} className="h5 p-3">
          <TotalCostCart count={cartCount} total_cost={total_cost} />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CartQuickView;
