import React from "react";
import { Navigate } from "react-router-dom";

import { isAuthenticated } from "./index";

const PrivateRoutes = ({ Component }) => {
  return isAuthenticated() ? <Component /> : <Navigate to="/sign-in" />
   

};

export default PrivateRoutes;
