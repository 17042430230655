import { API } from "../../backend";

const getPages = async () => {
  try {
    const response = await fetch(`${API}Pages/`, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

export default getPages;
