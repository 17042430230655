import React from 'react'
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
// Example items, to simulate fetching from another resources.

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: 'active', // default to "selected"
})`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 2rem;

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
    font-size: 10px;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const PaginatedItems = ({ count, limit, setLimit, offset, setOffset }) => {
  const endOffset = offset + limit;
  console.log(`Loading items from ${offset} to ${endOffset}`);
  // const currentItems = items.slice(offset, endOffset);
  const pageCount = Math.ceil(count / limit);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % count;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  return (
    <>
      <MyPaginate
        breakLabel="-"
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="mx-auto persian"
      />
    </>
  );
}


export default PaginatedItems