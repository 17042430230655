import React, { Component } from 'react';

class Head extends Component {
    render() {
        return (
            <div className='row py-auto d-flex justify-content-center heading'>
                 <div className=" d-flex justify-content-center my-auto align-middle h2">{this.props.title}</div>
            </div>
        );
    }
}

export default Head;