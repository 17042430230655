import React, { useState, useEffect } from "react";
import { default_image } from "../../backend";

export const getImage = async (url) => {
  try {
    const response = await fetch(url, { method: "GET" });
    return await response.json();
  } catch (err) {
    return console.log(err);
  }
};

const ImageHelper = ({ className, product }) => {
  const [imageUrl, setImageUrl] = useState(`${default_image}`);

  useEffect(() => {
    const loadImage = () => {
      if (product && product.product_images_link && product.product_images_link.length) {
        getImage(product.product_images_link[0]).then((data) => {
          if (data && data.error) {
            // setError(data.error);
            console.log(data.error);
          } else {
            setImageUrl(data && data.image);
          }
        });
      } else {
        setImageUrl(`${default_image}`);
      }
    };
    loadImage();
  }, [product]);

  return (
    <div className={"rounded p-2 text-center " + className} style={{ height: "100%" }}>
      <img src={imageUrl} style={{ maxWidth: "90%" }} className="mb-3 rounded text-center" alt="" />
    </div>
  );
};

export default ImageHelper;
