import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/src/less/react-checkbox-tree.less";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-checkbox-tree/src/scss/react-checkbox-tree.scss";
// import { FaCheckSquare } from "react-icons/fa";
// MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox, 
import { MdOpenInNew } from "react-icons/md";
import { MdOutlineSubdirectoryArrowLeft } from "react-icons/md";
import { VscExpandAll, VscCollapseAll } from "react-icons/vsc";
// import { LiaWindowCloseSolid } from "react-icons/lia";
// import { LuPanelTopOpen } from "react-icons/lu";
import { useParams } from "react-router-dom";

export default function CategoryFilter({ checked, setChecked, expanded, setExpanded, categoryNodes }) {
  const params = useParams();

  const set_clicked = (a) => {
    let arr = [];
    if (a && a.hasOwnProperty("children")) {
      arr = [a["value"]];

      for (var y in a.children) {
        arr.push(a.children[y]["value"]);
      }
    }

    return arr;
  };

  return (
    <CheckboxTree
      checkModel="all"
      direction="rtl"
      lang="fa"
      showNodeTitle={true}
      expandOnClick={false}
      noCascade={true}
      showNodeIcon={false}
      nodes={categoryNodes}
      checked={checked}
      expanded={expanded.length > 0 ? expanded : [Number(params.category__id)]}
      onCheck={(checked) => setChecked(checked)}
      onExpand={(expanded) => setExpanded(expanded)}
      onClick={(a) => {
        setChecked(set_clicked(a));
      }}
      icons={{
        check: '',
        uncheck: '',
        halfCheck: '',
        expandClose: <MdOutlineSubdirectoryArrowLeft />,
        expandOpen: <MdOpenInNew />,
        expandAll: <VscExpandAll />,
        collapseAll: <VscCollapseAll />,
        parentClose: "",
        parentOpen: "",
        leaf: "",
      }}
    />
  );
}
